import { IconButton, Tooltip } from "@mui/material";
import { Help } from "@mui/icons-material";

class SportClass {
    constructor(name, shorthand) {
        this.name = name;
        this.shorthand = shorthand;
    }
}

export const Sport = Object.freeze({
    NFL: new SportClass("NFL"),
    NBA: new SportClass("NBA"),
    WNBA: new SportClass("WNBA"),
    NHL: new SportClass("NHL"),
    MLB: new SportClass("MLB"),
    MLS: new SportClass("MLS"),
    NWSL: new SportClass("NWSL"),
    Prem: new SportClass("Premier League", "Prem"),
    LaLiga: new SportClass("La Liga"),
    All: new SportClass("All"),
});

const columns = [
    { attr: "name" },
    { attr: "sport", sm: "sprt" },
    { attr: "number", "label": "#" },
    { attr: "position", sm: "pos" },
    { attr: "height", sm: "ht" },
    { attr: "weight", sm: "wt" },
    { attr: "age" },
    { attr: "experience", sm: "exp", all: "exp" },
    { attr: "college" },
    { attr: "nationality", sm: "nat", tooltip: { all: true, component: <Tooltip leaveDelay={300} leaveTouchDelay={5000} enterTouchDelay={0} title="'Nationality' is an attribute used for Premier League, La Liga, MLS, and NWSL players, and indicates the country for which they play."><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>, smMessage: "'Nat', short for 'Nationality', is an attribute used for Premier League, La Liga, MLS, and NWSL players, and indicates the country for which they play." } },
    { attr: "shot", sm: "shot", tooltip: { all: true, component: <Tooltip leaveDelay={300} leaveTouchDelay={5000} enterTouchDelay={0} title="'Shot' is an attribute used for hockey players, and indicates the hand with which they shoot."><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>, smMessage: "'Shot' is an attribute used for hockey players, and indicates the hand with which they shoot." } },
    { attr: "bat", sm: "bat", tooltip: { all: true, component: <Tooltip leaveDelay={300} leaveTouchDelay={5000} enterTouchDelay={0} title="'Bat' is an attribute used for baseball players, and indicates the hand with which they bat."><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>, smMessage: "'Bat' is an attribute used for baseball players, and indicates the hand with which they bat." } },
    { attr: "throw", sm: "thr", tooltip: { all: true, component: <Tooltip leaveDelay={300} leaveTouchDelay={5000} enterTouchDelay={0} title="'Throw' is an attribute used for baseball players, and indicates the hand with which they throw."><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>, smClass: 'throw', smMessage: "'Thr', short for 'Throw', is an attribute used for baseball players, and indicates the hand with which they throw." } },
];

export const Columns = (sport) => {
    switch (sport) {
        case Sport.NFL:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "experience", "college"].includes(col.attr));
        case Sport.NBA:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "experience", "college"].includes(col.attr));
        case Sport.WNBA:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "experience", "college"].includes(col.attr));
        case Sport.NHL:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "experience", "shot"].includes(col.attr));
        case Sport.MLB:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "bat", "throw"].includes(col.attr));
        case Sport.MLS:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "nationality"].includes(col.attr));
        case Sport.NWSL:
            return columns.filter(col => ["name", "number", "position", "height", "age", "nationality"].includes(col.attr));
        case Sport.Prem:
            return columns.filter(col => ["name", "number", "position", "height", "age", "nationality"].includes(col.attr));
        case Sport.LaLiga:
            return columns.filter(col => ["name", "number", "position", "height", "weight", "age", "nationality"].includes(col.attr));
        case Sport.All:
            return columns.filter(col => ["name", "sport", "number", "position", "height", "weight", "age", "experience", "nationality", "shot", "bat", "throw"].includes(col.attr));
    }
}