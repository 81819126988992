import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { sportleDataActionCreators } from '../../stores/sportle-store';
import { bindActionCreators } from 'redux';
import { auth } from '../../firebase';
import { contrast, hexToRgb } from '../../helpers/functions';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import SportContainer from '../parts/sport-container';
import { Sport } from '../../helpers/sport-enum';

class PremHome extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            teams: [],
            premTeam: null,
            teamColors: null,
            help: false,
            loading: true,
            showLeaderboardByTeam: {}
        }

        this.load = this.load.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
        this.adjustTeam = this.adjustTeam.bind(this);
    }

    toggleHelp() {
        this.setState({ help: !this.state.help });
    }

    componentDidMount() {
        this._isMounted = true;
        document.body.classList = "prem"
        this.load(true)
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    load(start, callback) {
        document.querySelector("html").style.removeProperty('--contrastedColor');
        this.setState({ loading: true }, () => {
            this.props.getStreamData('Prem');
            this.props.actions.getSportleMetadata().then(() => {
                const sportleMetadata = this.props.sportle.sportleMetadata;
                if (this._isMounted) {
                    let maxGuessesBySport = sportleMetadata['maxGuesses'];
                    const maxGuesses = maxGuessesBySport["Prem"];
                    const showLeaderboardBySport = sportleMetadata['showLeaderboard'];
                    const showLeaderboardByTeam = showLeaderboardBySport['Prem'];
                    this.setState({ version: sportleMetadata['version'], showLeaderboardByTeam, maxGuesses, loading: false }, () => {
                        this.props.actions.getTeamsList(this.state.version, "Prem").then(() => {
                            const teams = this.props.sportle.teamsList;
                            this.setState({ teams: teams }, () => {
                                if (start) {
                                    if (localStorage.getItem('prem-team-' + auth.currentUser.uid)) {
                                        try {
                                            if (teams.map(t => t.name.toLowerCase()).includes((JSON.parse(localStorage.getItem('prem-team-' + auth.currentUser.uid)).label ?? JSON.parse(localStorage.getItem('prem-team-' + auth.currentUser.uid)).name).toLowerCase())) {
                                                this.adjustTeam(JSON.parse(localStorage.getItem('prem-team-' + auth.currentUser.uid)))
                                            } else {
                                                this.setColors();
                                            }
                                        } catch ({ name, message }) {
                                            if (message && message.toLowerCase().includes('not valid json')) {
                                                if (teams.map(t => t.name.toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e").replaceAll("/", "")).includes(localStorage.getItem('prem-team-' + auth.currentUser.uid).toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e").replaceAll("/", ""))) {
                                                    this.adjustTeam(teams.find(t => t.name.toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e").replaceAll("/", "") === localStorage.getItem('prem-team-' + auth.currentUser.uid).toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e").replaceAll("/", "")))
                                                }
                                            }
                                        }
                                    } else {
                                        this.setColors();
                                    }
                                }
                                if (callback) callback();
                                this.setState({ loadingTeams: false })
                            })
                        })
                    })
                }
            })
        })
    }

    adjustTeam(teamObj) {
        document.body.classList = "";
        const teamName = (teamObj.label || teamObj.name).toLowerCase();
        localStorage.setItem('prem-team-' + auth.currentUser.uid, JSON.stringify(teamObj))
        this.setColors(teamObj);
        let maxGuesses = this.props.sportle.sportleMetadata['maxGuesses']['Prem'];
        if (this.props.sportle.sportleMetadata["maxGuessesByTeam"] && this.props.sportle.sportleMetadata["maxGuessesByTeam"]["Prem"]) {
            const displayTeamName = teamName.split(" ").reduce((partial, val) => partial + val.charAt(0).toUpperCase() + val.slice(1) + " ", "").trim();
            const teamMaxGuesses = this.props.sportle.sportleMetadata["maxGuessesByTeam"]["Prem"][displayTeamName];
            if (teamMaxGuesses && teamMaxGuesses !== maxGuesses) {
                toast.dismiss();
                if (!sessionStorage.getItem(`max-guesses-snackbars-Prem-${displayTeamName}-${teamMaxGuesses}-vs-${maxGuesses}`))
                    toast.info(`${displayTeamName}: The maximum number of guesses for this selection is ${teamMaxGuesses}, rather than the usual ${maxGuesses}.`)
                sessionStorage.setItem(`max-guesses-snackbars-Prem-${displayTeamName}-${teamMaxGuesses}-vs-${maxGuesses}`, 'true');
                maxGuesses = teamMaxGuesses;
            }
        }
        this.setState({ maxGuesses, premTeam: teamName, teamColors: teamObj.value ? teamObj.value.colors : teamObj.colors });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.theme !== this.props.theme) {
            this.setColors();
        }
    }

    setColors(teamObj) {
        let teamColors = teamObj ? teamObj.value ? teamObj.value.colors : teamObj.colors : this.state.teamColors;
        if (!teamColors) {
            const primary = getComputedStyle(document.body).getPropertyValue('--bg');
            const secondary = getComputedStyle(document.body).getPropertyValue('--color');
            teamColors = { "primaryColor": primary?.replaceAll('#', ''), "secondaryColor": secondary?.replaceAll('#', '') };
        }
        let contrastedColor;
        const primaryContrastRgb = hexToRgb(teamColors.primaryColor);
        const bg = getComputedStyle(document.body).getPropertyValue('background-color').replaceAll('rgb(', '').replaceAll('rgba(', '').replaceAll(')', '').split(',')
        const primaryContrast = contrast(bg, primaryContrastRgb);
        if (primaryContrast < 2.15) {
            const secondaryContrastRgb = hexToRgb(teamColors.secondaryColor);
            const secondaryContrast = contrast(bg, secondaryContrastRgb);
            if (secondaryContrast < 2.15) {
               if (this.props.theme === 'dark') {
                contrastedColor = 'e9dede';
               } else {
                contrastedColor = '332f2f';
               }
            }
            else if (secondaryContrast > primaryContrast) {
                contrastedColor = teamColors.secondaryColor;
            }
        }
        if (!contrastedColor) {
            contrastedColor = teamColors.primaryColor;
        }
        document.querySelector("html").style.setProperty('--contrastedColor', '#' + contrastedColor);
        document.querySelector("html").style.setProperty('--bg', "#" + teamColors.primaryColor);
        document.querySelector("html").style.setProperty('--color', "#" + teamColors.secondaryColor);
        this.setModalColors(teamObj);
    }

    setModalColors(teamObj) {
        document.querySelector("html").style.setProperty('--contrastedModalColor', '');
        let teamColors = teamObj ? teamObj.value ? teamObj.value.colors : teamObj.colors : this.state.teamColors;
        if (!teamColors) {
            const primary = getComputedStyle(document.body).getPropertyValue('--bg');
            const secondary = getComputedStyle(document.body).getPropertyValue('--color');
            teamColors = { "primaryColor": primary?.replaceAll('#', ''), "secondaryColor": secondary?.replaceAll('#', '') };
        }
        let contrastedColor;
        const primaryContrastRgb = hexToRgb(teamColors.primaryColor);
        const bg = this.props.theme === 'light' ? hexToRgb('f6f4f3') : hexToRgb(getComputedStyle(document.body).getPropertyValue('--dark-bg'));
        const primaryContrast = contrast(bg, primaryContrastRgb);
        if (primaryContrast < 2.15) {
            const secondaryContrastRgb = hexToRgb(teamColors.secondaryColor);
            const secondaryContrast = contrast(bg, secondaryContrastRgb);
            if (secondaryContrast < 2.15) {
               if (this.props.theme === 'dark') {
                contrastedColor = 'e9dede';
               } else {
                contrastedColor = '332f2f';
               }
            }
            else if (secondaryContrast > primaryContrast) {
                contrastedColor = teamColors.secondaryColor;
            }
        }
        if (!contrastedColor) {
            contrastedColor = teamColors.primaryColor;
        }
        document.querySelector("html").style.setProperty('--contrastedModalColor', '#' + contrastedColor);
    }

    render() {
        return (
            <>
                <div className="home-wrapper">
                    <SportContainer loading={this.state.loading} game={Sport.Prem} database={this.props.database} showLeaderboardByTeam={this.state.showLeaderboardByTeam} users={this.props.users} sportsGuesses={this.props.sportsGuesses} sportsLeaderboards={this.props.sportsLeaderboards} load={this.load} version={this.state.version} team={this.state.premTeam} teamColors={this.state.teamColors} Teams={this.state.teams} adjustTeam={this.adjustTeam} maxGuesses={this.state.maxGuesses} toggleHelp={this.toggleHelp} />
                </div>
                <div className="app-store-container">
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936?platform=iphone">
                        <img className="app-store" src={require('../../assets/app-store-badge-128x128.png')} alt={"View In App Store"} />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">
                        <img className="google-play" src={require('../../assets/google_play.png')} alt={"View In Google Play"} />
                    </a>
                </div>
                {
                    this.state.help &&
                    <Modal isOpen={true} toggle={this.toggleHelp}>
                        <ModalHeader>HOW TO PLAY</ModalHeader>
                        <ModalBody>
                            <ul>
                                <li>You have {this.state.maxGuesses} attempts to guess the daily mystery player!</li>
                                <li><span className="c">Green</span> in any column indicates an exact match!</li>
                                <li><span className="almost">Yellow</span> in the position column indicates a partial match to the mystery player's position (if they play multiple positions)</li>
                                <li><span className="almost">Yellow</span> in any other column indicates this attribute is within 2 (number, height [2 inches/5 centimeters], age) of the mystery player</li>
                                <li>A new mystery player every day!</li>
                            </ul>
                        </ModalBody>
                        <IconButton className="close-modal-basic" onClick={this.toggleHelp}>
                            <Close />
                        </IconButton>
                    </Modal>
                }
            </>
        );
    }
}
export default connect(
    (state) => {
        const { sportle } = state;
        return {
            sportle
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sportleDataActionCreators), dispatch)
        }
    }
)(PremHome)