import React, { useEffect, useRef, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { Sport } from '../../helpers/sport-enum';

const DEFAULT_INPUT_WIDTH = 90;

const SelectTeam = (props) => {
    const [options, setOptions] = useState([]);
    const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);
    const autocompleteRef = useRef();

    useEffect(() => {
        let o = [];
        props.teams.forEach(teamObj => {
            const teamName = teamObj.name;
            const teamNames = teamName.split(" ");
            let capitalized = "";
            teamNames.forEach(n => {
                capitalized += n.charAt(0).toUpperCase() + n.slice(1) + " ";
            })
            const obj = { label: capitalized.trim(), value: teamObj, ...teamObj };
            o.push(obj);
        });

        o.sort(compare);
        setOptions(o);
    }, [props.teams]);

    const compareSequences = (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;

        return 0;
    }

    const compareStrings = (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;

        return 0;
    }

    const compare = (a, b) => {
        return compareSequences(a.sequence || 0, b.sequence || 0) || compareStrings(a.label, b.label)
    }

    const handleInputChange = (value) => {
        const width = getTextWidth(value, getCanvasFont(autocompleteRef.current.getElementsByTagName('input')[0])) + 60 //icon and padding
        setInputWidth(width);
    };

    function getTextWidth(text, font) {
        // re-use canvas object for better performance
        const canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
        const context = canvas.getContext("2d");
        context.font = font;
        const metrics = context.measureText(text);
        return metrics.width;
    }

    function getCssStyle(element, prop) {
        return window.getComputedStyle(element, null).getPropertyValue(prop);
    }

    function getCanvasFont(el = document.body) {
        const fontWeight = getCssStyle(el, 'font-weight') || 'normal';
        const fontSize = getCssStyle(el, 'font-size') || '16px';
        const fontFamily = getCssStyle(el, 'font-family') || 'Times New Roman';

        return `${fontWeight} ${fontSize} ${fontFamily}`;
    }

    return (
        <div className={`selection${!options.length ? ' empty' : !props.team ? ' pending-selection' : ''}${[Sport.Prem, Sport.LaLiga, Sport.MLS, Sport.NWSL].includes(props.game) ? ' wide' : ''}`} style={{ width: !options.length || !props.team ? '' : inputWidth }}>
            <Autocomplete
                ref={autocompleteRef}
                disableClearable
                autoHighlight
                selectOnFocus={false}
                classes={{ popper: `team-popper${[Sport.NFL, Sport.NBA, Sport.LaLiga, Sport.MLB].includes(props.game) ? ' wide' : [Sport.Prem, Sport.MLS, Sport.NWSL].includes(props.game) ? ' wider' : ''}` }}
                className={!options.length || !props.loaded ? 'disabled' : ''}
                disabled={!options.length || !props.loaded}
                onChange={(_, val) => props.onChange(val)}
                onInputChange={(e, val) => handleInputChange(val)}
                renderOption={(props, opt, state, ownerState) => <li {...props} {...state} style={{
                    color: opt.value.colors ? '#' + opt.value.colors.secondaryColor : '#24135f',
                    filter: opt.isFocused ? "brightness(75%)" : '',
                    backgroundColor: opt.value.colors ? '#' + opt.value.colors.primaryColor : 'white'
                }}>{opt.label}</li>
                }
                options={options.flatMap(x => x.options || x)}
                getOptionLabel={(option) => option.label || ""}
                groupBy={(option) => option.special ? "SPECIALS" : ((props.game.shorthand ?? props.game.name).toUpperCase() + ([Sport.Prem, Sport.LaLiga, Sport.MLS, Sport.NWSL].includes(props.game) ? " CLUBS" : " TEAMS"))}
                value={props.team ? options.flatMap(x => x.options || x).find(team => team.label.toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e").replaceAll("í", "i") === props.team.toLowerCase().replaceAll(" ", "").replaceAll(".", "").replaceAll("é", "e")) : null}
                renderInput={params => <TextField {...params} label={!options.length ? "Loading teams..." : props.team ? "Team" : "Select your team"} />}
            />
        </div>
    );
}

export default SelectTeam;