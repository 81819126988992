import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff, Email } from '@mui/icons-material';
import { IconButton, InputAdornment, Input } from '@mui/material';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, validatePassword, sendEmailVerification, sendPasswordResetEmail, updateProfile } from 'firebase/auth';
import { auth } from '../../firebase';
import { signInWithPopup, OAuthProvider } from "firebase/auth";
import { profanity } from '../../helpers/profanity';
import '../../stylesheets/sign-in.css';
import { ArrowBack, ArrowForward, InfoOutlined, LockOpen, Person } from '@mui/icons-material';
import { Button } from '@mui/material';
const appleProvider = new OAuthProvider('apple.com');
const googleProvider = new OAuthProvider('google.com');

const validRegex = RegExp(/^[a-zA-Z0-9-_\.\s]+$/);

let lastTouch = 0;
let wheelTimeout;
let transitionTimeout;

const SignIn = (props) => {
    const [login, setLogin] = useState(true);
    const [moveAnimation, setMoveAnimation] = useState(window.innerWidth > 850);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const [signup, setSignup] = useState(false);
    const [message, setMessage] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [movement, setMovement] = useState(0);
    const [transitionDuration, setTransitionDuration] = useState("0s");
    const [imgWidth, setImgWidth] = useState(0);

    useEffect(() => {
        document.body.classList = "login";

        return clearTimeout(transitionTimeout);
    }, [])

    const toggleSignup = () => {
        setSignup(!signup);
        setForgotPassword(false);
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setMessage('');
    }

    const toggleForgotPassword = () => {
        setForgotPassword(!forgotPassword);
        setSignup(false);
        setName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setMessage('');
    }

    const handleError = (error) => {
        switch (error.code) {
            case 'auth/invalid-email':
                setMessage('Invalid email format');
                return;
            case 'auth/invalid-credential':
                setMessage('Incorrect email/password');
                return;
            case 'auth/email-already-in-use':
                setMessage('Email already in use');
                return;
        }
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
    }

    const hasProfanity = (inputString) => {
        let isProfane = false;
        profanity.forEach((word) => {
            if (inputString.toLowerCase().split(' ').includes(word)) {
                isProfane = true;
            }
        });
        return isProfane;
    }

    const censor = (inputString) => {
        const inputStringSoup = inputString.split(' ');
        profanity.forEach((word) => {
            for (let i = 0; i < inputStringSoup.length; i++) {
                if (inputStringSoup[i].toLowerCase() == word) {
                    inputStringSoup[i] = inputStringSoup[i].replace(/./g, '*');
                }
            }
        });
        return inputStringSoup.join(' ');
    }

    const onSubmit = async (type, e) => {
        if (e) e.preventDefault();
        setSubmitting(true);
        setMessage('');
        switch (type) {
            case 'apple':
            case 'google':
                await signInWithPopup(auth, type === 'apple' ? appleProvider : googleProvider)
                    .then((result) => {
                        // The signed-in user info.
                        const user = result.user;
                        setSubmitting(false);
                        props.history.push('/');
                    })
                    .catch((error) => {
                        console.log("Error: " + error)
                        setSubmitting(false);
                        // ...
                    });
                break;
            case 'email':
                if (!email) {
                    setMessage('Please provide an email.');
                    setSubmitting(false);
                    return;
                }
                if (!forgotPassword) {
                    if (!password) {
                        setMessage('Please provide a password');
                        setSubmitting(false);
                        return;
                    }
                    if (!signup) {
                        await signInWithEmailAndPassword(auth, email, password)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                if (!user.emailVerified) {
                                    setMessage('This email address has not been verified.');
                                    setSubmitting(false);
                                    auth.signOut();
                                    return;
                                }
                                setSubmitting(false);
                                props.history.push('/');
                                // ...
                            })
                            .catch((error) => {
                                handleError(error);
                                setSubmitting(false);
                                // ..
                            });
                        break;
                    } else {
                        if (name == null) {
                            setMessage('Please provide your name');
                            setSubmitting(false);
                            return;
                        }
                        if (name.endsWith(' ')) {
                            setMessage('Names must not end in a space');
                            setSubmitting(false);
                            return;
                        }
                        if (!name.match(/^[a-zA-Z0-9]/)) {
                            setMessage('Names must start with a letter or number');
                            setSubmitting(false);
                            return;
                        }
                        if (!name.match(/[a-zA-Z].*[a-zA-Z]/)) {
                            setMessage('Names must contain at least two letters');
                            setSubmitting(false);
                            return;
                        }
                        if (name.includes('  ')) {
                            setMessage('Names cannot contain multiple consecutive spaces');
                            setSubmitting(false);
                            return;
                        }
                        if (name.includes('--')) {
                            setMessage('Names cannot contain multiple consecutive dashes');
                            setSubmitting(false);
                            return;
                        }
                        if (name.includes('__')) {
                            setMessage(
                                'Names cannot contain multiple consecutive underscores');
                            setSubmitting(false);
                            return;
                        }
                        if (name.includes('..')) {
                            setMessage(
                                'Names cannot contain multiple consecutive periods');
                            setSubmitting(false);
                            return;
                        }
                        if (!name.match(validRegex)) {
                            setMessage(
                                'Name may only contain letters, numbers, periods, spaces, dashes, and underscores');
                            setSubmitting(false);
                            return;
                        }
                        if (name.length >= 40) {
                            setMessage("Your name must be shorter than 40 characters");
                            setSubmitting(false);
                            return;
                        }
                        if (name.length <= 1) {
                            setMessage("Please provide a name longer than 1 character");
                            setSubmitting(false);
                            return;
                        }
                        if (name.toString().toLowerCase() == "n/a") {
                            setMessage("Please provide a valid name");
                            setSubmitting(false);
                            return;
                        }
                        const containsProfanity = hasProfanity(name);
                        if (containsProfanity) {
                            const cleanString = censor(name);
                            setMessage("Your name cannot contain profanity: " + cleanString);
                            setSubmitting(false);
                            return;
                        }
                        if (password !== confirmPassword) {
                            setMessage('Passwords must match.');
                            setSubmitting(false);
                            return;
                        }
                        await validatePassword(auth, password).then(async resp => {
                            if (!resp.isValid) {
                                if (!resp.meetsMinPasswordLength) {
                                    setMessage('Password must be at least ' + resp.passwordPolicy.customStrengthOptions.minPasswordLength + ' characters');
                                    setSubmitting(false);
                                    return;
                                }
                                if (!resp.meetsMaxPasswordLength) {
                                    setMessage('Password must be a max of ' + resp.passwordPolicy.customStrengthOptions.maxPasswordLength + ' characters');
                                    setSubmitting(false);
                                    return;
                                }
                            }
                            await createUserWithEmailAndPassword(auth, email, password)
                                .then(async (userCredential) => {
                                    // Signed in
                                    const user = userCredential.user;
                                    var settings = {
                                        url: 'https://www.sportlegames.com',
                                        handleCodeInApp: false,
                                    };

                                    await sendEmailVerification(user, settings).then(async () => {
                                        setMessage('We have sent an email to the provided address. Please click the link in the email to verify the address.')
                                        setSubmitting(false);
                                        try {
                                            await updateProfile(user, { displayName: name });
                                        } catch (e) {
                                            //ignore
                                        }
                                        return;
                                    }).catch(error => {
                                        console.log(error);
                                        setMessage('Error sending confirmation email.')
                                        setSubmitting(false);
                                        return;
                                    });
                                    // ...
                                })
                                .catch((error) => {
                                    handleError(error);
                                    setSubmitting(false);
                                    // ..
                                });
                        })
                    }
                } else {
                    await sendPasswordResetEmail(auth, email).then(() => {
                        setMessage('We have sent an email to the provided address.')
                        setSubmitting(false);
                        return;
                    }).catch(error => {
                        console.log(error);
                        setMessage('Error sending confirmation email.')
                        setSubmitting(false);
                        return;
                    });
                }
                break;
            default: break;
        }
    }

    const getImgWidth = () => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const els = document.querySelectorAll('.images img');
                if (els && els.length) {
                    resolve(([...els].find(e => e.offsetWidth !== 0)?.offsetWidth ?? 0) + 2);
                }
                resolve(0);
            }, 50);
        });
    }

    const setImgWidths = () => {
        getImgWidth().then(width => {
            if (imgWidth !== width) {
                setImgWidth(width);
            }
        });
    }

    const imgs = [
        [
            { "src": require('../../assets/Hotpot 0.png'), 'alt': 'Game Info', 'width': '50%', onLoad: setImgWidths },
            { "src": require('../../assets/Hotpot 1.png'), 'alt': 'Game Info', 'width': '50%', onLoad: setImgWidths }
        ],
        [
            { "src": require('../../assets/Hotpot 2.png'), 'alt': 'Game Info', 'width': '50%', onLoad: setImgWidths },
            { "src": require('../../assets/Hotpot 3.png'), 'alt': 'Game Info', 'width': '50%', onLoad: setImgWidths }
        ],
        [{ "src": require('../../assets/Hotpot 4.png'), 'alt': 'Game Info', 'width': '50%', onLoad: setImgWidths },]
    ];

    const handleWheel = (e) => {
        clearTimeout(wheelTimeout);
        handleMovement(e.deltaX);
        wheelTimeout = setTimeout(() => handleMovementEnd(), 100);
    };

    const handleMovement = (delta) => {
        clearTimeout(transitionTimeout);

        const maxLength = imgs.length - 1;
        let nextMovement = movement + delta;

        if (nextMovement < 0) {
            nextMovement = 0;
        }

        setImgWidths();
        if (nextMovement > maxLength * imgWidth) {
            nextMovement = maxLength * imgWidth;
        }

        setMovement(nextMovement);
        setTransitionDuration("0s");
    };

    const handleTouchStart = (e) => {
        if (e.target.classList.contains('move')) return;
        lastTouch = e.nativeEvent.touches[0].clientX;
    };

    const handleTouchMove = e => {
        if (e.target.classList.contains('move')) return;
        const delta = lastTouch - e.nativeEvent.touches[0].clientX;
        lastTouch = e.nativeEvent.touches[0].clientX;

        handleMovement(delta);
    };

    const handleTouchEnd = (e) => {
        if (e.target.classList.contains('move')) return;
        handleMovementEnd();
        lastTouch = 0;
    };

    const handleMovementEnd = () => {
        setImgWidths();
        const endPosition = movement / imgWidth;
        const endPartial = endPosition % 1;
        const endingIndex = endPosition - endPartial;
        const deltaInteger = endingIndex - currentIndex;

        let nextIndex = endingIndex;

        if (deltaInteger >= 0) {
            if (endPartial >= 0.1) {
                nextIndex += 1;
            }
        } else if (deltaInteger < 0) {
            nextIndex = currentIndex - Math.abs(deltaInteger);
            if (endPartial > 0.9) {
                nextIndex += 1;
            }
        }

        transitionTo(nextIndex, Math.min(0.5, 1 - Math.abs(endPartial)));
    };

    const transitionTo = (index, duration) => {
        setMoveAnimation(false);
        setImgWidths();
        setCurrentIndex(index);
        setMovement(index * (imgWidth * 2));
        setTransitionDuration(`${duration}s`);

        transitionTimeout = setTimeout(() => {
            setTransitionDuration("0s");
        }, duration * 100);
    };

    const maxLength = imgs.length - 1;
    const maxMovement = (maxLength * 2) * imgWidth;

    return (
        <main>
            <section>
                <div className="sign-in">
                    <div className="home-wrapper" style={{ position: 'relative' }}>
                        <div className={`buttons-container${login ? ' hide-mobile' : ''}`}>
                            {
                                movement !== 0 && (
                                    <IconButton
                                        className={`back move${moveAnimation ? ' animate' : ''}`}
                                        onMouseEnter={() => setMoveAnimation(false)}
                                        onClick={() => {
                                            transitionTo(currentIndex - 1, 0.5);
                                        }}
                                    >
                                        <ArrowBack htmlColor="#003e7c" />
                                    </IconButton>
                                )
                            }
                            <div className="images"
                            //onWheel={handleWheel}
                            //onTouchStart={handleTouchStart}
                            //onTouchMove={handleTouchMove}
                            //onTouchEnd={handleTouchEnd}
                            >
                                <div className="swiper" style={{
                                    transform: `translateX(${movement * -1}px)`,
                                    transitionDuration: transitionDuration,
                                }}>
                                    {imgs.map((list) => list.map(img =>
                                        <img src={img.src} alt={img.alt} onLoad={img.onLoad()} />,
                                    ))}
                                    <div className="app-store-container sign-in">
                                        <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936?platform=iphone">
                                            <img className="app-store" src={require('../../assets/app-store-badge-128x128.png')} alt={"View In App Store"} />
                                        </a>
                                        <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">
                                            <img className="google-play" src={require('../../assets/google_play.png')} alt={"View In Google Play"} />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {
                                movement !== maxMovement && (
                                    <IconButton
                                        className={`next move${moveAnimation ? ' animate' : ''}`}
                                        onMouseEnter={() => setMoveAnimation(false)}
                                        onClick={() => {
                                            transitionTo(currentIndex + 1, 0.5);
                                        }}
                                    >
                                        <ArrowForward htmlColor="#003e7c" />
                                    </IconButton>
                                )
                            }
                        </div>
                        <div className="spacer" />
                        <div className={`sign-in-form${login ? '' : ' hide-mobile'}`}>
                            <div className="continue-with-platforms">
                                <button type="button" className='btn'
                                    onClick={(e) => onSubmit('apple', e)}
                                >
                                    <img src={require('../../assets/appleid_button@2x.png')} alt={"Continue with Apple"} />
                                </button>

                                <button type="button" className="login-with-google-btn" onClick={(e) => onSubmit('google', e)}>
                                    Continue with Google
                                </button>
                            </div>

                            <hr />

                            <h3 className={forgotPassword ? 'forgot-password' : ''}>{signup ? 'Sign Up' : forgotPassword ? 'Forgot Password' : 'Login'}</h3>

                            <form onSubmit={(e) => onSubmit("email", e)}>
                                {signup &&
                                    <>
                                        <Input
                                            placeholder='Name'
                                            id="name"
                                            type="name"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <Person htmlColor={'white'} />
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </>
                                }
                                <Input
                                    placeholder='Email'
                                    id="email"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <Email htmlColor={'white'} />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />

                                {!forgotPassword && <>
                                    <Input
                                        placeholder='Password'
                                        id="password"
                                        type={
                                            showPassword
                                                ? "text"
                                                : "password"
                                        }
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)
                                                    }
                                                    onMouseDown={() =>
                                                        setShowPassword(!showPassword)
                                                    }
                                                >
                                                    {showPassword ? (
                                                        <Visibility htmlColor={'white'} />
                                                    ) : (
                                                        <VisibilityOff htmlColor={'white'} />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </>
                                }

                                {signup &&
                                    <>
                                        <Input
                                            placeholder='Confirm Password'
                                            id="confirm-password"
                                            type={
                                                showConfirmPassword
                                                    ? "text"
                                                    : "password"
                                            }
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            value={confirmPassword}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setShowConfirmPassword(!showConfirmPassword)
                                                        }
                                                        onMouseDown={() =>
                                                            setShowConfirmPassword(!showConfirmPassword)
                                                        }
                                                    >
                                                        {showConfirmPassword ? (
                                                            <Visibility htmlColor={'white'} />
                                                        ) : (
                                                            <VisibilityOff htmlColor={'white'} />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </>}


                                <div id="login-container" style={{ lineHeight: '17px', marginTop: message ? '10px' : '20px' }}>
                                    {message && <span style={{ fontSize: message.length > 40 ? '0.8rem' : '1.0rem' }}>{message}</span>}
                                    <button disabled={submitting} type="submit" id="login-btn" className={`btn${forgotPassword && !submitting ? ' forgot-password' : ''}`}>{submitting ? <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                        : signup ? "Sign Up" : forgotPassword ? "Send Password Reset Link" : "Login"}</button>
                                </div>
                            </form>
                            {!forgotPassword && <button disabled={submitting} className="btn btn-link signup" onClick={toggleSignup} >{signup ? 'Already have an account?' : "Don't have an account?"}</button>}
                            {!signup && <button disabled={submitting} className="btn btn-link signup" onClick={toggleForgotPassword} >{forgotPassword ? "Back" : "Forgot password?"}</button>}
                        </div>
                        <div className="login-btn-container">
                            <Button size={'large'} onClick={() => { setLogin(!login); setMoveAnimation(true) }} startIcon={login ? <InfoOutlined /> : <LockOpen />}>{login ? "About the game" : "Login"}</Button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default SignIn;