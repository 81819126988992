import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import PlayerData from './player-data';

class PlayerModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        const { player } = this.props;
        return (
            <Modal isOpen={true} toggle={() => this.props.toggleModal()} className="player-modal">
                <ModalBody>
                    <PlayerData player={player} league={this.props.league} />
                </ModalBody>
            </Modal >
        );
    }
}
export default connect()(PlayerModal);
