import React, { useEffect, useState } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { Close, Help } from '@mui/icons-material';
import { Columns, Sport } from '../../helpers/sport-enum';
import GuessRow from './guess-row';

let tooltipTimeout = null;

const GuessBoard = (props) => {
    const imperialLocalStorage = localStorage.getItem('imperial');
    const [useImperial, setUseImperial] = useState(imperialLocalStorage === undefined || imperialLocalStorage !== 'false');
    const [heightWeightTooltip, setHeightWeightTooltip] = useState(false);
    const [tooltipIsOpen, setTooltipIsOpen] = useState({});

    useEffect(() => {
        if (heightWeightTooltip) {
            document.addEventListener('mousedown', handleClickOutside);
        }
    }, [heightWeightTooltip])

    const handleClickOutside = (event) => {
        const infoBox = document.getElementsByClassName('info-box');
        if (infoBox && infoBox.length && event && event.target && !infoBox[0].contains(event.target)) {
            document.removeEventListener('mousedown', handleClickOutside);
            setHeightWeightTooltip(false);
        }
    }

    const toggleHeightWeightTooltip = (e) => {
        const infoBox = document.getElementsByClassName('info-box');
        if (!infoBox || !infoBox.length || (e && e.target && !infoBox[0].contains(e.target))) {
            setHeightWeightTooltip(!heightWeightTooltip);
        }
    }

    const toggleImperial = () => {
        setUseImperial(!useImperial);
        props.toggleImperial();
    }

    const tooltip =
        <Modal isOpen={true} toggle={toggleHeightWeightTooltip} className="tooltip-modal">
            <ModalBody>
                <span>Height and weight use the imperial system by default. If you prefer to use the metric system, you may change it below.</span>
                <br />
                <br />
                <div>
                    <label className="checkbox-label">Metric</label>
                    <div className="toggle-switch" onClick={toggleImperial}>
                        <input checked={useImperial} onChange={() => null} type="checkbox" />
                        <span className="slider"></span>
                    </div>
                    <label className="checkbox-label">Imperial</label>
                </div>
            </ModalBody>
            <IconButton className="close-modal-basic" onClick={toggleHeightWeightTooltip}>
                <Close />
            </IconButton>
        </Modal>

    const toggleTooltip = (open, attr, clickaway) => {
        let t = { ...tooltipIsOpen };
        t[attr] = open;
        setTooltipIsOpen(t);
        if (typeof tooltipTimeout === "number") {
            clearTimeout(tooltipTimeout);
        }
        if (open) {
            tooltipTimeout = setTimeout(function () {
                setTooltipIsOpen(false, attr);
            }, 5000);
        }

    }

    return (
        <>
            {heightWeightTooltip &&
                tooltip
            }
            <div className={`board${props.guesses.find(g => g.name === props.c.name) || (props.guesses.length >= props.maxGuesses) ? ' no-animation' : ''}`}>
                <div className={`table${props.game == Sport.All ? ' all' : ''} table-striped table-light`}>
                    <div className={`table-head ${(props.game.shorthand ?? props.game.name).replaceAll(" ", "").toLowerCase()}`}>
                        <div className="table-row">
                            {Columns(props.game).map((col, i) =>
                                <>
                                    <div className={`table-cell${col.sm ? ' lg-col' : ''}`} scope="col" onClick={['height', 'weight'].includes(col.attr) ? toggleHeightWeightTooltip : null}>{props.game == Sport.All && col.all ? col.all : col.label || col.attr}{['height', 'weight'].includes(col.attr) && <Tooltip leaveDelay={300} leaveTouchDelay={0} title="Set Measuring System"><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>}{col.tooltip && (!col.tooltip.all || props.game == Sport.All) && col.tooltip.component}</div>
                                    {col.sm &&
                                        (['height', 'weight'].includes(col.attr) ?
                                            <div key={'a' + new Date().getTime()} className="table-cell sm-col" scope="col" onClick={toggleHeightWeightTooltip}>{col.sm}<Tooltip leaveDelay={300} leaveTouchDelay={0} title="Set Measuring System"><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip></div>
                                            : <ClickAwayListener onClickAway={tooltipIsOpen[col.attr] ? () => toggleTooltip(false, col.attr) : () => null}><div key={new Date().getTime()} className="table-cell sm-col" scope="col" onClick={col.tooltip && (!col.tooltip.all || props.game == Sport.All) ? () => toggleTooltip(true, col.attr, true) : null}>{col.sm}{col.tooltip && (!col.tooltip.all || props.game == Sport.All) && col.tooltip.smMessage && <Tooltip classes={{ popper: 'column-helper-tooltip-popper' }} open={tooltipIsOpen[col.attr]} onOpen={() => toggleTooltip(true, col.attr)} onClose={() => toggleTooltip(false, col.attr)} leaveDelay={300} leaveTouchDelay={5000} enterTouchDelay={0} title={col.tooltip?.smMessage}><IconButton className="table-column-help no-bg"><Help /></IconButton></Tooltip>}</div></ClickAwayListener>)}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={`table-body ${(props.game.shorthand ?? props.game.name).replaceAll(" ", "").toLowerCase()}`}>
                        {
                            props.guesses.map((guess, idx) =>
                                <GuessRow game={props.game} sport={guess.sport ?? props.game.shorthand ?? props.game.name} key={idx} failedToGuessCorrectly={idx >= props.maxGuesses} imperial={useImperial} c={props.c} guess={guess} conferences={props.conferences} />
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default GuessBoard;

