import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import { InputAdornment, Input, IconButton, Icon } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { auth } from '../../firebase';
import { Link } from 'react-router-dom';
import { Clear, Search } from '@mui/icons-material';
import { Sport } from '../../helpers/sport-enum';

const bronze = "BRONZE";
const silver = "SILVER";
const gold = "GOLD";

const min_games_played = "min_games_played";
const min_win_pct = "min_win_pct";

const DAILY = "DAILY";
const ALL_TIME = "ALL_TIME";

const trophyReqs = {
    true: {
        [bronze]: { [min_games_played]: 10, [min_win_pct]: .500 },
        [silver]: { [min_games_played]: 25, [min_win_pct]: .625 },
        [gold]: { [min_games_played]: 50, [min_win_pct]: .700 }
    },
    false: {
        [bronze]: { [min_games_played]: 10, [min_win_pct]: .600 },
        [silver]: { [min_games_played]: 25, [min_win_pct]: .667 },
        [gold]: { [min_games_played]: 50, [min_win_pct]: .750 }
    }
};

const goldTrophy = <FontAwesomeIcon icon={faTrophy} />;
const silverTrophy = <FontAwesomeIcon icon={faTrophy} />;
const bronzeTrophy = <FontAwesomeIcon icon={faTrophy} />;

let filter_delay_timeout;

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sortedLeaderboard: [],
            showTrophyRequirements: false,
            view: DAILY,
            sortDirection: "asc",
            sorting: false
        }

        this.setLeaderboardView = this.setLeaderboardView.bind(this);
        this.toggleTrophyRequirements = this.toggleTrophyRequirements.bind(this);
        this.leaderboardRows = this.leaderboardRows.bind(this);
        this.searchLeaderboard = this.searchLeaderboard.bind(this);
    }

    componentDidMount() {
        if (this.props.canView && this.props.leaderboard) {
            this.setLeaderboardView(this.state.view);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if ((this.props.canView || this.state.view !== DAILY) && ((this.props.leaderboard && !prevProps.leaderboard) || (!this.props.leaderboard && prevProps.leaderboard) || (this.props.leaderboard && prevProps.leaderboard && JSON.stringify(this.props.leaderboard) !== JSON.stringify(prevProps.leaderboard)))) {
            this.setLeaderboardView(this.state.view, true);
        }
    }

    searchLeaderboard(filter) {
        const filter_delay = 500;
        clearTimeout(filter_delay_timeout);
        if (filter) {
            filter_delay_timeout = setTimeout(() => {
                const res = this.state.sortedLeaderboard.filter(player => player.value['name'] != null &&
                    player.value['name']
                        .toLowerCase()
                        .replaceAll(".", "")
                        .replaceAll("'", "")
                        .replaceAll("ñ", "n")
                        .replaceAll("ã", "a")
                        .replaceAll("ø", "o")
                        .replaceAll("á", "a")
                        .replaceAll("à", "a")
                        .replaceAll("ï", "i")
                        .replaceAll("ë", "e")
                        .replaceAll("é", "e")
                        .replaceAll("É", "E")
                        .replaceAll("ó", "o")
                        .replaceAll("ø", "o")
                        .replaceAll("Ø", "O")
                        .replaceAll("ö", "o")
                        .replaceAll("í", "i")
                        .replaceAll("ú", "u")
                        .replaceAll("ü", "u")
                        .replaceAll("Á", "A")
                        .replaceAll("Í", "I")
                        .replaceAll("Ó", "O")
                        .replaceAll("ß", "b")
                        .replaceAll("č", "c")
                        .replaceAll("ć", "c")
                        .replaceAll("ç", "c")
                        .replaceAll("Ç", "C")
                        .includes(filter
                            .toLowerCase()
                            .replaceAll(".", "")
                            .replaceAll("'", "")
                            .replaceAll("ñ", "n")
                            .replaceAll("ã", "a")
                            .replaceAll("ø", "o")
                            .replaceAll("á", "a")
                            .replaceAll("à", "a")
                            .replaceAll("ï", "i")
                            .replaceAll("ë", "e")
                            .replaceAll("é", "e")
                            .replaceAll("É", "E")
                            .replaceAll("ó", "o")
                            .replaceAll("ø", "o")
                            .replaceAll("Ø", "O")
                            .replaceAll("ö", "o")
                            .replaceAll("í", "i")
                            .replaceAll("ú", "u")
                            .replaceAll("ü", "u")
                            .replaceAll("Á", "A")
                            .replaceAll("Í", "I")
                            .replaceAll("Ó", "O")
                            .replaceAll("ß", "b")
                            .replaceAll("č", "c")
                            .replaceAll("ć", "c")
                            .replaceAll("ç", "c")
                            .replaceAll("Ç", "C")));
                this.setState({
                    filteredLeaderboard: res,
                });
            }, filter_delay);
        } else {
            this.setState({
                filteredLeaderboard: null,
            });
        }
        this.setState({ filter })
    }

    setLeaderboardView(view, isWebsocket = false) {
        if (this.props.leaderboard != null) {
            let gameEndDate = new Date();
            gameEndDate.setDate(gameEndDate.getDate() + 1);
            gameEndDate.setHours(0, 0, 0, 0);
            if (view == DAILY) {
                if (this.props.canView) {
                    let map = new Map(Object.entries(JSON.parse(JSON.stringify(this.props.leaderboard))).filter(([k, v]) => new Date(v.expiry).getTime() === gameEndDate.getTime()));
                    map = this.applyWinPct(map);
                    this.sortLeaderboard(map, view, null, isWebsocket);
                } else {
                    this.setState({ sortedLeaderboard: [], sortDirection: "asc", sort: "Guesses" })
                }
            } else if (view == ALL_TIME) {
                let map = new Map(Object.entries(JSON.parse(JSON.stringify(this.props.leaderboard))).filter(([k, v]) => v.wins + v.losses >= 10));
                map = this.applyWinPct(map);
                this.sortLeaderboard(map, view, null, isWebsocket);
            }
        }
        this.setState({
            view,
            filteredLeaderboard: null,
            filter: ''
        });
    }

    leaderboardRows() {
        let elements = [];
        let i = 0;
        for (const player of (this.state.filteredLeaderboard ?? this.state.sortedLeaderboard)) {
            elements.push(
                <div key={i} className={`table-row${player.user === auth.currentUser.uid ? ' user' : ''}`}>
                    {(this.state.view === DAILY ? ['idx', 'name', 'numGuesses', 'game_duration', 'streak', 'wins', 'losses', 'win_pct'] : ['idx', 'name', 'wins', 'losses', 'win_pct']).map((cell, idx) =>
                        <div className={"table-cell"} key={idx}>
                            {this.getDisplayValue(player.value, i === 0 || cell !== 'idx' ? null : this.state.sortedLeaderboard[i - 1], cell !== 'idx' || i === this.state.sortedLeaderboard.length - 1 ? null : this.state.sortedLeaderboard[i + 1], cell)}
                            {cell === 'name' &&
                                this.getTrophy(player)}
                            {player.user === auth.currentUser.uid && cell === 'name' &&
                                !player.value['name'] && <Link to="/profile" className="profile-link">(Update Name)</Link>}
                        </div>
                    )}
                </div>
            )
            i++;
        }
        return elements;
    }

    getDisplayValue(player, previousPlayer, nextPlayer, column) {
        if (column == 'idx') {
            let returnVal = "";
            if ((previousPlayer != null && previousPlayer.value != null &&
                player['idx'] == previousPlayer.value['idx']) ||
                (nextPlayer != null && nextPlayer.value != null &&
                    player['idx'] == nextPlayer.value['idx'])) {
                returnVal = 'T';
            }
            returnVal += `${player['idx'] + 1}.`;
            return returnVal;

        }
        if (column == 'game_duration') {
            return this.getDurationText(player['game_duration']);
        }
        if (column == 'streak' &&
            player['streak'] != null &&
            player['streak'] != '') {
            if (player['streak'] == 0) {
                return "N/A";
            }
            return `${player['streak']}d`;
        }

        return (player[column] ??
            (column == 'numGuesses' ? 'Failed' : 'N/A'))
            .toString();
    }

    getDurationText(durationInSeconds) {
        if (durationInSeconds < 60) {
            //under one minute
            return `${durationInSeconds}s`;
        } else {
            if (durationInSeconds < 3600) {
                //under one hour
                return moment.utc(durationInSeconds * 1000).format("m:ss")
            } else {
                return moment.utc(durationInSeconds * 1000).format("h:mm:ss")
            }
        }
    }

    ordinal(number) {
        if (number >= 11 && number <= 13) {
            return 'th';
        }

        switch (number % 10) {
            case 1:
                return 'st';
            case 2:
                return 'nd';
            case 3:
                return 'rd';
            default:
                return 'th';
        }
    }

    teamName = (team) => {
        const teamNames = team.split(" ");
        let capitalized = "";
        teamNames.forEach(n => {
            capitalized += n.charAt(0).toUpperCase() + n.slice(1) + " ";
        })
        return capitalized;
    }

    meetsTrophyReqs = (trophyType, totalGames, winPct) => {
        return totalGames >=
            trophyReqs[this.props.sport == Sport.All][trophyType][
            min_games_played] &&
            winPct >=
            trophyReqs[this.props.sport == Sport.All][trophyType][min_win_pct];
    }

    getTrophy = (player) => {
        const wins = player.value['wins'];
        const losses = player.value['losses'];
        const totalGames = wins + losses;
        const winPct = parseFloat(player.value['win_pct']);
        if (this.meetsTrophyReqs(bronze, totalGames, winPct)) {
            if (this.meetsTrophyReqs(gold, totalGames, winPct)) {
                return <span className="trophy gold">{goldTrophy}</span>;
            } else if (this.meetsTrophyReqs(silver, totalGames, winPct)) {
                return <span className="trophy silver">{silverTrophy}</span>;
            } else if (this.meetsTrophyReqs(bronze, totalGames, winPct)) {
                return <span className="trophy bronze">{bronzeTrophy}</span>;
            }
        }
    }

    toggleTrophyRequirements() {
        this.setState({
            showTrophyRequirements: !this.state.showTrophyRequirements
        });
    }

    getWinPct(wins, losses) {
        var winPct = wins / (wins + losses);
        return winPct;
    }

    applyWinPct(entries) {
        entries.forEach((entry) => {
            var totalGames = entry['wins'] + entry['losses'];
            var winPct = entry['wins'] / totalGames;
            winPct = winPct == 1
                ? '1.000'
                : winPct == 0 ? '.000' : winPct.toString().substring(1, 5).padEnd(4, '0');
            entry['win_pct'] = winPct;
        });

        return entries;
    }

    sortLeaderboard(
        leaderboard, view, primarySort, isWebsocket = false) {
        primarySort ?? (isWebsocket && this.state.sort ? primarySort = this.state.sort : primarySort = view == DAILY ? "Guesses" : "%");
        view ?? (view = this.state.view);
        leaderboard ?? (leaderboard = new Map(this.state.sortedLeaderboard.map((obj) => [obj.user, obj.value])));
        const prevSort = this.state.sort;
        if (leaderboard != null) {
            this.setState({ sorting: true, sort: primarySort }, () => {
                let sortedEntries = Array.from(leaderboard, ([user, value]) => ({ user, value }));
                const users = Array.from(Object.entries(this.props.users) || {}, ([user, value]) => ({ user, value }));
                sortedEntries.forEach((player) => {
                    player.value['name'] = users
                        .find((entry) => entry.user == player.user)
                        ?.value['display_name'];
                });
                let sortAttr;
                sortedEntries.sort((e1, e2) => {
                    let diff = 0;
                    let ranPrimarySort = true;
                    switch (primarySort.toLowerCase()) {
                        case 'name':
                            sortAttr = 'name';
                            diff = this.sortByName(e2, e1);
                            break;
                        case 'time':
                            sortAttr = 'game_duration';
                            diff = this.sortByGameDuration(e2, e1);
                            break;
                        case 'streak':
                            sortAttr = 'streak';
                            diff = this.sortByStreak(e2, e1);
                            break;
                        case 'w':
                        case 'wins':
                            sortAttr = 'wins';
                            diff = this.sortByWins(e2, e1);
                            break;
                        case 'l':
                        case 'losses':
                            sortAttr = 'losses';
                            diff = this.sortByLosses(e2, e1);
                            break;
                        case '%':
                            sortAttr = 'win_pct';
                            diff = this.sortByRecord(e2, e1);
                            break;
                        default:
                            sortAttr = 'numGuesses';
                            ranPrimarySort = false;
                            diff = this.sortByGuesses(e2, e1);
                            break;
                    }
                    if (diff == 0) {
                        if (e1.value['match'] === null || e1.value['match'] === undefined) {
                            e1.value['match'] = e2.value['match'] === null || e2.value['match'] === undefined || e2.value['match'] === e1.value['id'] ? e2.value['id'] : e2.value['match']
                        }
                        if (e2.value['match'] === null || e2.value['match'] === undefined) {
                            e2.value['match'] = e1.value['match'] === null || e1.value['match'] === undefined || e1.value['match'] === e2.value['id'] ? e1.value['id'] : e1.value['match']
                        }
                    }
                    if (diff == 0 && ranPrimarySort && view === DAILY) {
                        diff = this.sortByGuesses(e2, e1);
                    }
                    if (diff == 0 && view === DAILY) {
                        if (e2.value['correct'] == true && e1.value['correct'] == true) {
                            diff = this.sortByGameDuration(e2, e1);
                        }
                    }
                    if (diff == 0 && view === DAILY) {
                        diff = this.sortByStreak(e2, e1);
                    }
                    if (diff == 0) {
                        if (view !== DAILY || (e2.value['correct'] == true && e1.value['correct'] == true)) {
                            diff = this.sortByRecord(e2, e1);
                        }
                    }
                    if (diff == 0) {
                        if (view !== DAILY ||
                            (e2.value['correct'] == true && e1.value['correct'] == true)) {
                            diff = this.sortByWins(e2, e1);
                        }
                    }
                    if (diff == 0) {
                        diff = this.sortByName(e2, e1);
                    }
                    return diff;
                });
                let newSortDirection = isWebsocket ? this.state.sortDirection : ["l", "losses", "guesses"].includes(primarySort.toLowerCase()) ? "asc" : "desc";
                let reverse = false;
                if (!isWebsocket && prevSort == primarySort &&
                    ((this.state.sortDirection == "desc" &&
                        !["l", "losses", "guesses"].includes(primarySort.toLowerCase())) ||
                        (this.state.sortDirection == "asc" &&
                            ["l", "losses", "guesses"].includes(primarySort.toLowerCase())))) {
                    //already sorting on this, do the opposite direction
                    newSortDirection = ["l", "losses", "guesses"].includes(primarySort.toLowerCase()) ? "desc" : "asc";
                    reverse = true;
                }
                sortedEntries.forEach((player, idx) => {
                    var previousPlayer = idx == 0 ? null : sortedEntries[idx - 1];
                    if ((newSortDirection == "desc" && !["losses", "numGuesses"].includes(sortAttr)) ||
                        (newSortDirection == "asc" && ["losses", "numGuesses"].includes(sortAttr))) {
                        if (sortAttr != null &&
                            previousPlayer != null &&
                            player.value[sortAttr] == previousPlayer.value[sortAttr]) {
                            player.value['idx'] = previousPlayer.value['idx'];
                        } else {
                            player.value['idx'] = idx;
                        }
                    } else if (player.value['idx'] == null) {
                        player.value['idx'] = idx;
                    }
                });
                if (reverse) {
                    sortedEntries.reverse();
                }
                this.setState({
                    sortedLeaderboard: sortedEntries,
                    sortDirection: newSortDirection,
                    sorting: false
                });
            });
        }
    }

    sortByGuesses(e2, e1) {
        var diff = e2.value['correct'] != e1.value['correct']
            ? e2.value['correct'] == true
                ? 1
                : -1
            : e2.value['numGuesses'] == e1.value['numGuesses']
                ? 0
                : e2.value['numGuesses'] < e1.value['numGuesses']
                    ? 1
                    : -1;
        return diff;
    }

    sortByGameDuration(e2, e1) {
        if (e2.value['game_duration'] < e1.value['game_duration']) {
            return 1;
        } else if (e1.value['game_duration'] < e2.value['game_duration']) {
            return -1;
        }
        return 0;
    }

    sortByStreak(e2, e1) {
        if (e2.value['correct'] == true && e1.value['correct'] == true) {
            if (e2.value['streak'] != null && e1.value['streak'] != null) {
                if (e2.value['streak'] > e1.value['streak']) {
                    return 1;
                } else if (e1.value['streak'] > e2.value['streak']) {
                    return -1;
                }
            } else if (e2.value['streak'] != null && e1.value['streak'] == null) {
                return 1;
            } else if (e1.value['streak'] != null && e2.value['streak'] == null) {
                return -1;
            }
        }
        return 0;
    }

    sortByRecord(e2, e1) {
        const winPct1 = e1.value['win_pct'];
        const winPct2 = e2.value['win_pct'];
        if (winPct2 != null &&
            winPct1 == null) {
            return 1;
        } else if (winPct2 == null &&
            winPct1 != null) {
            return -1;
        } else if (winPct2 != null &&
            winPct1 != null) {
            if (winPct2 > winPct1) {
                return 1;
            } else if (winPct1 > winPct2) {
                return -1;
            }
        }
        return 0;
    }

    sortByWins(e2, e1) {
        if (e2.value['wins'] != null && e1.value['wins'] == null) {
            return 1;
        } else if (e2.value['wins'] == null && e1.value['wins'] != null) {
            return -1;
        } else if (e2.value['wins'] != null && e1.value['wins'] != null) {
            if (e2.value['wins'] > e1.value['wins']) {
                return 1;
            } else if (e1.value['wins'] > e2.value['wins']) {
                return -1;
            }
        }
        return 0;
    }

    sortByLosses(e2, e1) {
        if (e2.value['losses'] != null && e1.value['losses'] == null) {
            return 1;
        } else if (e2.value['losses'] == null && e1.value['losses'] != null) {
            return -1;
        } else if (e2.value['losses'] != null && e1.value['losses'] != null) {
            if (e2.value['losses'] < e1.value['losses']) {
                return 1;
            } else if (e1.value['losses'] < e2.value['losses']) {
                return -1;
            }
        }
        return 0;
    }

    sortByName(e2, e1) {
        if ((e2.value['name'] == null || e2.value['name'] == '') &&
            (e1.value['name'] != null && e1.value['name'] != '')) {
            return -1;
        } else if ((e1.value['name'] == null || e1.value['name'] == '') &&
            (e2.value['name'] != null && e2.value['name'] != '')) {
            return 1;
        } else if ((e2.value['name'] != null && e2.value['name'] != '') &&
            (e1.value['name'] != null && e1.value['name'] != '')) {
            return e1.value['name'].localeCompare(e2.value['name']);
        }
        return 0;
    }


    render() {
        const { sortedLeaderboard, filteredLeaderboard } = this.state;
        let userPosition = -1;
        let userPositionStr;
        if (this.props.currentUser != null) {
            const obj = sortedLeaderboard.find(
                (player) => player.user == this.props.currentUser.uid);
            if (obj) {
                userPosition = obj.value['idx'];
                if (sortedLeaderboard.filter(player => player.value['idx'] === userPosition).length > 1) {
                    userPositionStr = `(T)${userPosition + 1}`;
                } else {
                    userPositionStr = `${userPosition + 1}`
                }
            }
        }
        const leaderboard = filteredLeaderboard ?? sortedLeaderboard;
        return (
            <Modal isOpen={true} toggle={this.props.toggleModal} className={`leaderboard${this.props.canView ? '' : ' empty'}`}>
                <ModalHeader toggle={this.props.toggleModal}>
                    <div className="leaderboard-view-tabs">
                        <button type="button" onClick={() => this.state.view === DAILY ? null : this.setLeaderboardView(DAILY)} className={`btn tab ${this.state.view === DAILY ? 'active' : ''}`}>Daily</button>
                        <button type="button" onClick={() => this.state.view === ALL_TIME ? null : this.setLeaderboardView(ALL_TIME)} className={`btn tab ${this.state.view === ALL_TIME ? 'active' : ''}`}>All Time</button>
                    </div>
                    <div className="leaderboard-header">{this.props.team ? this.teamName(this.props.team) : "All Sports"} Leaderboard ({this.state.view == DAILY ? moment().format(moment.localeData().longDateFormat('L')) : "All Time"})</div>
                    {userPositionStr && <div className="leaderboard-subheader">YOUR POSITION: {userPositionStr}{this.ordinal(userPosition + 1)} out of {sortedLeaderboard.length}</div>}
                </ModalHeader>
                <ModalBody>
                    {
                        !this.props.canView && this.state.view === DAILY ? <div>
                            <p><b>You cannot view today's leaderboard until you have finished playing.</b></p>
                            <p>Viewing others' levels of success could provide an unfair advantage to those playing later in the day.</p>
                            <p>For instance, seeing that others have struggled could indicate that today's mystery player is not highly popular.</p>
                        </div>
                            :
                            <>
                                {sortedLeaderboard.length > 0 ?
                                    <Input className="filter" onChange={(e) => this.searchLeaderboard(e.target.value)} value={this.state.filter} placeholder="Search"
                                        startAdornment={
                                            <InputAdornment position='start'>
                                                <Icon size={'small'}><Search /></Icon>
                                            </InputAdornment>}
                                        endAdornment={
                                            this.state.filter && <InputAdornment position="end">
                                                <IconButton size={'small'} onClick={() => this.searchLeaderboard("")}>
                                                    <Clear />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    : null}
                                <div className={`board no-animation`} style={this.state.view == ALL_TIME ? { marginBottom: '0px' } : {}}>
                                    <div className={`leaderboard ${this.state.view.toLowerCase()} table table-striped table-light`}>
                                        <div className={`table-head${this.props.sport === Sport.All ? ' all' : ''}`}>
                                            <div className="table-row">
                                                {(this.state.view === DAILY ? ['', 'NAME', 'GUESSES', 'TIME', 'STREAK', 'W', 'L', '%'] : ['', 'NAME', 'WINS', 'LOSSES', '%']).map((cell, idx) =>
                                                    <div key={idx} onClick={() => cell === '' ? null : this.sortLeaderboard(null, null, cell)} className="table-cell" scope="col">{cell}{this.state.sort && this.state.sort.toUpperCase() === cell ? this.state.sorting ? <div className="spinner-border" role="status" style={{ width: '1rem', height: '1rem' }} /> : <FontAwesomeIcon icon={this.state.sortDirection === "desc" ? faArrowDown : faArrowUp} color="white" size={'sm'} /> : null}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className={`table-body`}>
                                            {leaderboard.length > 0 ? this.leaderboardRows() : <div className="no-players-alert">{sortedLeaderboard.length > 0 ? `No users found${this.state.view == DAILY ? " today" : ""} with the provided filter.` : `Nobody has played ${this.props.sport === Sport.All ? "this game mode" : "with this team"} ${this.state.view == DAILY ? "today" : "for 10 total days"}, yet. Be the first!`}</div>}
                                        </div>
                                    </div>
                                </div>
                                {this.state.view == ALL_TIME &&
                                    <div className="all-time-note"><small>*Only users who have played at least 10 total days of this game mode will appear here.</small></div>}
                                {sortedLeaderboard.length ? <button type="button" className="btn btn-link help trophy-button" style={{ fontStyle: 'italic', lineHeight: '15px' }} onClick={this.toggleTrophyRequirements}>What does a trophy mean next to somebody's name?</button> : null}
                                <div className={`trophy-requirements${this.state.showTrophyRequirements ? ' expanded' : ''}`}>
                                    <div className="requirement-container">
                                        {this.props.sport === Sport.All &&
                                            <div className="all-indicator">(Due to its difficulty, this game mode has a lower respective threshold for each trophy than do other sports)</div>
                                        }
                                        {[{ color: gold, trophy: goldTrophy }, { color: silver, trophy: silverTrophy }, { color: bronze, trophy: bronzeTrophy }].map((trophyObj, i) =>
                                            <div key={i} className={`requirement ${trophyObj.color.toLowerCase()}`}>{trophyObj.trophy} = {trophyReqs[this.props.sport == Sport.All][trophyObj.color][min_games_played]}+ games played & {trophyReqs[this.props.sport == Sport.All][trophyObj.color][min_win_pct].toString().substring(1).padEnd(4, "0")} or greater win %</div>
                                        )}
                                        <button type="button" className="btn btn-link help" style={{ fontStyle: 'italic', fontSize: '90%' }} onClick={this.toggleTrophyRequirements}>Hide</button>
                                    </div>
                                </div>
                            </>
                    }
                </ModalBody>
            </Modal>
        );
    }
}
export default connect()(Leaderboard);
