// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCAj7fn6VlcelPVw4Ec55_DTiGe7QDc7MU",
    authDomain: "sportle-d324f.firebaseapp.com",
    databaseURL: "https://sportle-d324f-default-rtdb.firebaseio.com",
    projectId: "sportle-d324f",
    storageBucket: "sportle-d324f.appspot.com",
    messagingSenderId: "136671944255",
    appId: "1:136671944255:web:a24ac8fd6610d14164628a",
    measurementId: "G-JKGZ1KC4R9"
};

// Initialize Firebase
const firebase = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebase);
export const auth = getAuth(firebase);
export default firebase;