import React, { useEffect, useState } from 'react';
import { ageByDateOfBirth, birthdayIsToday, calcInches, convertToImperial, convertToMetric, getDisplayValue } from '../../helpers/functions';
import PlayerModal from '../parts/player-modal';
import { Tooltip } from '@mui/material';
import { Columns, Sport } from '../../helpers/sport-enum';

const GuessRow = (props) => {
    const isMobile = window.innerWidth <= 850;
    const [imgLoaded, setImgLoaded] = useState(false);
    const [imgError, setImgError] = useState(false);
    const [showPlayerData, setShowPlayerData] = useState(false);
    const [birthdayTooltipIsOpen, setBirthdayTooltipIsOpen] = useState(false);

    useEffect(() => {
        const tableBody = document.getElementsByClassName("table-body")[0];
        if (tableBody) {
            if (!tableBody.scrollHeight) {
                new Promise(resolve => setTimeout(resolve, 200)).then(() => {
                    tableBody.scrollTop = tableBody.scrollHeight;
                })
            } else {
                tableBody.scrollTop = tableBody.scrollHeight;
            }
        }
    }, [imgLoaded])

    const style = (c, guess, attr, failedToGuessCorrectly) => {
        if (!['weight', 'height', 'position', 'age', 'exp', 'experience'].includes(attr) && guess[attr] === c[attr]) {
            return failedToGuessCorrectly ? 'failed' : 'c';
        } else if (attr === 'age') {
            const guessAge = ageByDateOfBirth(guess);
            const cAge = ageByDateOfBirth(c);
            if (guessAge == cAge) {
                return failedToGuessCorrectly ? 'failed' : 'c';
            }
            if (guessAge != null &&
                cAge != null &&
                !isNaN(guessAge) &&
                !isNaN(cAge)) {
                if (Math.abs(parseInt(guessAge) - parseInt(cAge)) <= 2) {
                    return 'almost';
                }
            }
        } else if (['number'].includes(attr) && guess[attr] && c[attr] && guess[attr] !== "--" && c[attr] !== "--" && (guess[attr] - c[attr] <= 2 && guess[attr] - c[attr] >= -2)) {
            return 'almost';
        } else if (attr === 'weight') {
            let guessWeight = guess[attr];
            let cWeight = c[attr];
            if (props.imperial) {
                if (["Prem", "La Liga"].includes(guess.sport ?? props.sport) && guessWeight && guessWeight !== "N/A") {
                    guessWeight = convertToImperial(guess[attr], attr);
                }

                if (["Prem", "La Liga"].includes(c.sport ?? props.sport) && cWeight && cWeight !== "N/A") {
                    cWeight = convertToImperial(c[attr], attr);
                }
            } else {
                if (!["Prem", "La Liga"].includes(guess.sport ?? props.sport) && guessWeight && guessWeight !== "N/A") {
                    guessWeight = convertToMetric(guess[attr], attr);
                }

                if (!["Prem", "La Liga"].includes(c.sport ?? props.sport) && cWeight && cWeight !== "N/A") {
                    cWeight = convertToMetric(c[attr], attr);
                }
            }

            guessWeight = ['--', 'N/A'].includes(guessWeight) || !guessWeight ? guessWeight : parseInt(guessWeight);
            cWeight = ['--', 'N/A'].includes(cWeight) || !cWeight ? cWeight : parseInt(cWeight);

            if (guessWeight === cWeight) {
                return failedToGuessCorrectly ? 'failed' : 'c';
            } else if ((props.imperial && guessWeight - cWeight <= 10 && guessWeight - cWeight >= -10) || (!props.imperial && guessWeight - cWeight <= 4.5 && guessWeight - cWeight >= -4.5)) {
                return 'almost'
            };
        } else if (attr === 'height') {
            let guessInches = guess[attr];
            let cInches = c[attr];

            if (props.imperial) {
                if (["Prem", "La Liga"].includes(guess.sport ?? props.sport) && guessInches !== "N/A") {
                    guessInches = calcInches(convertToImperial(guess[attr], attr));
                } else if (guessInches !== "N/A") {
                    guessInches = calcInches(guess[attr], attr);
                }

                if (["Prem", "La Liga"].includes(c.sport ?? props.sport) && cInches !== "N/A") {
                    cInches = calcInches(convertToImperial(c[attr], attr));
                } else if (cInches !== "N/A") {
                    cInches = calcInches(c[attr], attr);
                }
            } else {
                if (!["Prem", "La Liga"].includes(guess.sport ?? props.sport) && guessInches !== "N/A") {
                    guessInches = convertToMetric(guess[attr], attr).match(/\d+/);
                    if (guessInches.length) {
                        guessInches = guessInches[0];
                    }
                }

                if (!["Prem", "La Liga"].includes(c.sport ?? props.sport) && cInches !== "N/A") {
                    cInches = convertToMetric(c[attr], attr).match(/\d+/);
                    if (cInches.length) {
                        cInches = cInches[0];
                    }
                }
            }

            guessInches = guessInches === "N/A" ? guessInches : parseInt(guessInches);
            cInches = cInches === "N/A" ? cInches : parseInt(cInches);

            if (guessInches === cInches) {
                return failedToGuessCorrectly ? 'failed' : 'c';
            } else if ((props.imperial && guessInches - cInches <= 2 && guessInches - cInches >= -2) || (!props.imperial && guessInches - cInches <= 5 && guessInches - cInches >= -5)) {
                return 'almost'
            };
        } else if (attr === 'position' && (c.sport === guess.sport || (["Prem", "La Liga", "MLS", "NWSL"].includes(c.sport) && ["Prem", "La Liga", "MLS", "NWSL"].includes(guess.sport)))) {
            if (getDisplayValue(c[attr], 'position', isMobile, props.sport, props.imperial, props.game) === getDisplayValue(guess[attr], 'position', isMobile, props.sport, props.imperial, props.game)) {
                return failedToGuessCorrectly ? 'failed' : 'c';
            } else if (c[attr].includes('/') || guess[attr].includes('/')) {
                const cPositions = c[attr].split('/');
                const guessPositions = guess[attr].split('/');
                const intersection = cPositions.filter(position => guessPositions.includes(position));
                if (intersection.length) { return 'almost'; }
            }
        } else if (attr === 'experience' || attr === 'exp') {
            const compareGuess = (guess['experience'] ?? guess['exp']) === 'R' ? 1 : (guess['experience'] ?? guess['exp']);
            const compareC = (c['experience'] ?? c['exp']) === 'R' ? 1 : (c['experience'] ?? c['exp']);
            if (compareGuess === compareC) {
                return failedToGuessCorrectly ? 'failed' : 'c';
            } else if (compareGuess - compareC <= 2 && compareGuess - compareC >= -2) {
                return 'almost';
            }
        } else if (attr === 'college') {
            const gConference = props.conferences.find(conf => conf.teams.includes(guess[attr]));
            const cConference = props.conferences.find(conf => conf.teams.includes(c[attr]));
            if (gConference !== "Unknown" && gConference.name === cConference.name) { return 'almost'; }
        }

        return ''
    }

    return (
        <>
            {showPlayerData &&
                <PlayerModal player={props.guess} league={props.guess.sport ?? props.game.shorthand ?? props.game.name} toggleModal={setShowPlayerData} />
            }
            <div className="table-row">
                {Columns(props.game).map((col, idx) =>
                    <div key={idx} className={"table-cell " + style(props.c, props.guess, props.game != Sport.All || col.attr !== 'experience' ? col.attr : 'exp', props.failedToGuessCorrectly)}>{col.attr === 'name' ? <button className="btn btn-link player" onClick={setShowPlayerData}>{props.guess[col.attr]}</button> : col.attr === 'age' ? ageByDateOfBirth(props.guess) : (props.guess[col.attr] || (col.attr === 'experience' && props.guess['exp']) ? getDisplayValue(props.guess[col.attr] ?? (col.attr === 'experience' && props.guess['exp']) ?? null, col.attr, isMobile, props.sport, props.imperial, props.game) : 'N/A')}
                        {col.attr === 'name' &&
                            props.guess.img && !imgError && <div style={!imgLoaded ? { display: 'none' } : {}}><img src={props.guess.img} onClick={setShowPlayerData} alt={props.guess.name} onLoad={setImgLoaded} onError={setImgError} /></div>}
                        {col.attr === 'age' &&
                            birthdayIsToday(props.guess) && <Tooltip open={birthdayTooltipIsOpen} onOpen={() => setBirthdayTooltipIsOpen(true)} onClose={() => setBirthdayTooltipIsOpen(false)} enterTouchDelay={0} title="Today is their birthday!"><span className="birthday" onClick={() => setBirthdayTooltipIsOpen(true)} /></Tooltip>}
                    </div>
                )}
            </div>
        </>
    );
}

export default GuessRow;