import { ref, set, get, update } from "firebase/database";
import moment from 'moment';
import { auth } from "../firebase";
import { Sport } from "./sport-enum";

var To = new Date('2022-06-11 00:00:00.000');

export const Oo = function (e, players) {
    var t, n = Io(e);
    t = n % players.length;
    if (players[t].removed === true || players[t].removed === "true") {
        const nextValidPlayer = players.find(p => !p.removed && players.indexOf(p) > t);
        if (nextValidPlayer) {
            return nextValidPlayer;
        } else {
            return players.find(p => p.removed !== true && p.removed !== "true");
        }
    } else {
        return players[t]
    }
}
function Co(e, t) {
    var n = new Date(e)
        , a = new Date(t).setHours(0, 0, 0, 0) - n.setHours(0, 0, 0, 0);
    return Math.ceil(a / 864e5)
}
function Io(e) {
    return Co(To, e)
}

export const getDisplayValue = function (val, attr, isMobile, sport, imperial, game) {
    if (val) {
        if (attr === "position") {
            switch (val) {
                case 'Defender':
                case 'Defense':
                    val = 'D';
                    break;
                case 'Midfielder':
                    val = 'M';
                    break;
                case 'Center':
                    val = 'C';
                    break;
                case 'Forward':
                case 'Attacker':
                    val = 'F';
                    break;
                case 'Left Wing':
                    val = 'LW';
                    break;
                case 'Right Wing':
                    val = 'RW';
                    break;
                case 'Goalkeeper':
                case 'Goaltender':
                    val = 'GK';
                    break;
                default:
                    break;
            }
        } else if (attr === 'nationality') {
            switch (val.toLowerCase()) {
                case 'gb-wls':
                    val = isMobile ? 'WLS.' : 'Wales';
                    break;
                case 'brazil' && game == Sport.All:
                    val = isMobile ? 'BRZ.' : 'Brazil';
                    break;
                case 'costa rica' && game == Sport.All:
                    val = 'C.R.';
                    break;
                case 'puerto rico' && game == Sport.All:
                    val = 'P.R.';
                    break;
                case 'dominican republic':
                    val = 'D.R.';
                    break;
                case 'republic of ireland' && game == Sport.All:
                case 'ireland' && isMobile:
                    val = isMobile ? 'IRE.' : 'Ire.';
                    break;
                case 'new zealand':
                    val = 'NZL.';
                    break;
                case 'cote d’ivoire':
                case 'ivory coast':
                    val = isMobile ? 'IVO.' : 'Ivo.';
                    break;
                case 'united states':
                    val = 'USA';
                    break;
                case 'switzerland' && game == Sport.All:
                    val = isMobile ? 'SWZ.' : 'Switz.';
                    break;
                default:
                    if (isMobile && game == Sport.All) {
                        val = val.length > 4 ? val.substr(0, 3).toUpperCase() + "." : val;
                    } else if (!isMobile && game == Sport.All) {
                        val = val.length > 8 ? val.charAt(0).toUpperCase() + val.substr(1, 5) + "." : val;
                    } else {
                        val = val.length > 12 ? val.charAt(0).toUpperCase() + val.substr(1, 9) + "." : val;
                    }
                    break;
            }
        } else if (['height', 'weight'].includes(attr)) {
            if (["Prem", "La Liga"].includes(sport)) {
                if (imperial) {
                    val = convertToImperial(val, attr);
                }
            } else {
                if (!imperial) {
                    val = convertToMetric(val, attr);
                }
            }
        }
    }
    return val && val !== 'null' ?
        val +
        (attr === 'height' && !imperial
            ? 'cm'
            : '') : 'N/A'
}

export const convertToMetric = (val, attr) => {
    if (attr === 'height' && val !== "N/A" && val) {
        const inches = calcInches(val);
        val = `${Math.round(2.54 * inches)}`;
    } else if (attr === 'weight' && val !== "N/A" && val) {
        val = Math.round(0.453592 * val);
    }

    return val ? val : 'N/A';
}

export const convertToImperial = (val, attr) => {
    if (attr === 'height') {
        if (!val || val === "N/A") { return "N/A"; }
        var realFeet = ((val * 0.393700) / 12);
        var feet = Math.floor(realFeet);
        var inches = Math.round((realFeet - feet) * 12);
        if (inches === 12) { feet++; inches = 0; }
        val = feet + "'" + inches;
    } else if (attr === 'weight') {
        if (!val || val === "N/A") { return "N/A"; }
        val = Math.round(2.2046 * val);
    }

    return val;
}

export const calcInches = (height) => {
    let inches;
    inches = parseInt(height[0]) * 12;
    inches += parseInt(height.substr(2));
    return inches;
}

export const ageByDateOfBirth = (player) => {
    if (player.dateOfBirth &&
        player.dateOfBirth !== 'N/A' &&
        player.dateOfBirth !== '--') {
        try {
            var today = moment()
            var birthDate = moment(player.dateOfBirth);
            var age = today.year() - birthDate.year();
            var m = today.month() - birthDate.month();
            if (m < 0 || (m === 0 && today.date() < birthDate.date())) {
                age--;
            }
            return age;
        } catch (e) {
            return player.age || 'N/A';
        }
    }
    return player.age || 'N/A';
}

export const birthdayIsToday = (player) => {
    if (player.dateOfBirth != null) {
        try {
            var today = moment()
            var birthDate = moment(player.dateOfBirth);
            return birthDate.month() === today.month() && birthDate.date() === today.date();
        }
        catch (e) {
            return false;
        }
    }
    return false;
}

const allTimeNumbersRoute = (gameType, uid) => `all-time-numbers/${uid}/${gameType}`

export const setAllTimeNumbersFromAllTimeNumbers = async function (allTimeNumbers, gameType, correct, database, uid) {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    if (!allTimeNumbers || !Number.isInteger(parseInt(allTimeNumbers['total-wins'])) || !Number.isInteger(parseInt(allTimeNumbers['total-losses']))) {
        const obj = { "streak": correct ? 1 : 0, "total-wins": correct ? 1 : 0, "total-losses": correct ? 0 : 1, "last-entry": moment(today).format("YYYY-MM-DD HH:mm:ss.SSSSSS") }
        // await set(ref(database, allTimeNumbersRoute(gameType, uid)), obj);
        return obj;
    } else {
        let obj = {
            "total-wins": setTotalWins(allTimeNumbers, correct),
            "total-losses": setTotalLosses(allTimeNumbers, correct),
            "streak": setStreak(allTimeNumbers, correct, today),
            "last-entry": moment(today).format("YYYY-MM-DD HH:mm:ss.SSSSSS")
        }

        //  await set(ref(database, allTimeNumbersRoute(gameType, uid)), obj);
        return obj;
    }
}

export const setAllTimeNumbers = async function (gameType, correct, database, uid) {
    await getAllTimeNumbersDb(gameType, database, uid).then(async ls => {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        if (!ls || !Number.isInteger(parseInt(ls['total-wins'])) || !Number.isInteger(parseInt(ls['total-losses']))) {
            const obj = { "streak": correct ? 1 : 0, "total-wins": correct ? 1 : 0, "total-losses": correct ? 0 : 1, "last-entry": moment(today).format("YYYY-MM-DD HH:mm:ss.SSSSSS") }
            await set(ref(database, allTimeNumbersRoute(gameType, uid)), obj);
        } else {
            let obj = {
                "total-wins": setTotalWins(ls, correct),
                "total-losses": setTotalLosses(ls, correct),
                "streak": setStreak(ls, correct, today),
                "last-entry": moment(today).format("YYYY-MM-DD HH:mm:ss.SSSSSS")
            }

            await set(ref(database, allTimeNumbersRoute(gameType, uid)), obj);
        }
    })
}

export const setTotalWins = function (ls, correct) {
    return correct ? (parseInt(ls['total-wins']) + 1) : ls['total-wins'];
}

export const setTotalLosses = function (ls, correct) {
    return !correct ? (parseInt(ls['total-losses']) + 1) : ls['total-losses'];
}

export const setStreak = function (ls, correct, today) {
    const lastEntry = ls['last-entry'];
    let lastEntryDatePlusOneDay = lastEntry && new Date(lastEntry);
    if (lastEntryDatePlusOneDay) {
        lastEntryDatePlusOneDay.setDate(lastEntryDatePlusOneDay.getDate() + 1);
    }
    const lastEntryIsYesterday = lastEntryDatePlusOneDay && lastEntryDatePlusOneDay.getTime() === today.getTime();
    if (!ls['streak'] || !Number.isInteger(parseInt(ls['streak'])) || !lastEntryIsYesterday) {
        return correct ? 1 : 0
    } else {
        return correct ? (parseInt(ls['streak']) + 1) : 0
    }
}

export const getAllTimeNumbersFromRecordAndStreak = function (allTimeRecord, streak) {
    let returnVal = '';

    if (allTimeRecord) {
        returnVal += '%0D%0A';

        if (streak && streak > 0) {
            returnVal += "Streak: " + streak + ' day' + (streak != 1 ? 's' : '');
            returnVal += '%0D%0A';
        }

        returnVal += "All-time: " + allTimeRecord;
    }

    return returnVal;
}

export const getAllTimeNumbers = async function (gameType, database, uid) {
    let returnVal = '';

    let ls = await getAllTimeNumbersDb(gameType, database, uid);
    if (ls) {
        if (Number.isInteger(parseInt(ls['total-wins'])) && Number.isInteger(parseInt(ls['total-losses']))) {
            returnVal += '%0D%0A';

            if (Number.isInteger(parseInt(ls['streak']))) {
                const streak = await getStreak(ls, null, database, uid);
                if (streak > 0) {
                    returnVal += "Streak: " + streak + ' day' + (streak != 1 ? 's' : '');
                    returnVal += '%0D%0A';
                }
            }

            returnVal += "All-time: " + await getTotalWins(ls, null, database, uid) + '-' + await getTotalLosses(ls, null, database, uid)
        }
    }

    return returnVal;
}

export const getTotalWins = async function (ls, gameType, database, uid) {
    if (!ls && gameType) {
        ls = await getAllTimeNumbersDb(gameType, database, uid);
    }

    if (ls) {
        return parseInt(ls['total-wins']);
    } else {
        return '';
    }
}

export const getTotalLosses = async function (ls, gameType, database, uid) {
    if (!ls && gameType) {
        ls = await getAllTimeNumbersDb(gameType, database, uid);
    }

    if (ls) {
        return parseInt(ls['total-losses']);
    } else {
        return '';
    }
}

export const getRecordAndStreak = async function (ls, gameType, database, uid, lsOverrideDb, leaderboards) {
    let localStorageNumbersPreDb;
    let resetAllTimeNumbers = false;
    let sportKey;
    let teamKey;
    if (!ls && gameType) {
        localStorageNumbersPreDb = lsOverrideDb ? localStorage.getItem(gameType) : null;
        if (localStorageNumbersPreDb) { localStorageNumbersPreDb = JSON.parse(localStorageNumbersPreDb); }

        if (gameType === 'all-numbers') {
            sportKey = 'All'
            teamKey = 'all'
        } else {
            const keys = gameType.split("-numbers-");
            if (keys.length) {
                switch (keys[0].toLowerCase()) {
                    case 'laliga':
                        sportKey = 'La Liga';
                        teamKey = keys[1];
                        break;
                    case 'prem':
                        sportKey = 'Prem';
                        teamKey = keys[1];
                        break;
                    default:
                        sportKey = keys[0].toUpperCase()
                        teamKey = keys[1]
                        break;
                }
            }
        }
        const sportLeaderboard = leaderboards[sportKey];
        if (sportLeaderboard) {
            const teamLeaderboard = sportLeaderboard[teamKey];
            if (teamLeaderboard) {
                ls = getAllTimeNumbersFromLeaderboardJson(teamLeaderboard, uid);
            }
        }
    }

    ls = ls ?? { 'total-wins': 0, 'total-losses': 0, 'streak': 0, 'last-entry': 0 };

    let record;
    let wins = await getTotalWins(ls, null, database, uid);
    let losses = await getTotalLosses(ls, null, database, uid);

    let streak;
    if (ls) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        const lastEntry = ls['last-entry'];
        let lastEntryDatePlusOneDay = lastEntry && new Date(lastEntry);
        if (lastEntryDatePlusOneDay) {
            lastEntryDatePlusOneDay.setDate(lastEntryDatePlusOneDay.getDate() + 1);
        }
        const lastEntryIsYesterday = lastEntryDatePlusOneDay && lastEntryDatePlusOneDay.getTime() === today.getTime();
        streak = lastEntryIsYesterday || (lastEntry && new Date(lastEntry).getTime() === today.getTime()) ? parseInt(ls['streak']) : 0;
    } else {
        streak = '';
    }

    //update db if there are more numbers here than there; this means they played on the web app before there was a db
    if (localStorageNumbersPreDb) {
        //wins
        const localWins = await getTotalWins(localStorageNumbersPreDb);
        //losses
        const localLosses = await getTotalLosses(localStorageNumbersPreDb);
        //streak
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        const lastEntry = localStorageNumbersPreDb['last-entry'];
        let lastEntryDatePlusOneDay = lastEntry && new Date(lastEntry);
        if (lastEntryDatePlusOneDay) {
            lastEntryDatePlusOneDay.setDate(lastEntryDatePlusOneDay.getDate() + 1);
        }
        const lastEntryIsYesterday = lastEntryDatePlusOneDay && lastEntryDatePlusOneDay.getTime() === today.getTime();
        const localStreak = lastEntryIsYesterday || (lastEntry && new Date(lastEntry).getTime() === today.getTime()) ? parseInt(localStorageNumbersPreDb['streak']) : 0;

        if ((!wins || localWins > wins) || (!losses || localLosses > losses) || (localStreak !== streak || lastEntry !== ls['last-entry'])) {
            resetAllTimeNumbers = true;
            //wins
            wins = wins ? wins + localWins : localWins;
            ls['total-wins'] = wins;
            //losses
            losses = losses ? losses + localLosses : localLosses;
            ls['total-losses'] = losses;
            //streaks
            streak = streak ? streak + localStreak : localStreak;
            ls['streak'] = streak;
        }
    }

    if (ls && (wins > 0 || losses > 0)) {
        record = wins + '-' + losses;
    } else {
        record = null;
    }

    if (resetAllTimeNumbers) {
        if (!sportKey || !teamKey) {
            if (gameType === 'all-numbers') {
                sportKey = 'All'
                teamKey = 'all'
            } else {
                const keys = gameType.split("-numbers-");
                if (keys.length) {
                    switch (keys[0].toLowerCase()) {
                        case 'laliga':
                            sportKey = 'La Liga';
                            teamKey = keys[1];
                            break;
                        case 'prem':
                            sportKey = 'Prem';
                            teamKey = keys[1];
                            break;
                        default:
                            sportKey = sportKey[0].toUpperCase()
                            teamKey = keys[1]
                            break;
                    }
                }
            }
        }
        if (teamKey) {
            switch (teamKey) {
                case 'atleticodemadrid':
                    teamKey = 'atlético de madrid';
                    break;
                case 'fcbarcelona':
                    teamKey = 'fc barcelona';
                    break;
                case 'realmadrid':
                    teamKey = 'real madrid';
                    break;
                case 'getafecf':
                    teamKey = 'getafe cf';
                    break;
                case 'valenciacf':
                    teamKey = 'valencia cf';
                    break;
                case 'astonvilla':
                    teamKey = 'aston villa';
                    break;
                case 'brightonandhovealbion':
                    teamKey = 'brighton and hove albion';
                    break;
                case 'crystalpalace':
                    teamKey = 'crystal palace';
                    break;
                case 'manchestercity':
                    teamKey = 'manchester city';
                    break;
                case 'manchesterunited':
                    teamKey = 'manchester united';
                    break;
                case 'tottenhamhotspur':
                    teamKey = 'tottenham hotspur';
                    break;
                case 'lutontown':
                    teamKey = 'luton town';
                    break;
                case 'westhamunited':
                    teamKey = 'west ham united';
                    break;
                case 'wolverhamptonwanderers':
                    teamKey = 'wolverhampton wanderers';
                    break;
                case 'lagalaxy':
                    teamKey = 'la galaxy';
                    break;
                case 'intermiamicf':
                    teamKey = 'inter miami cf';
                    break;
                case 'angelcityfc':
                    teamKey = 'angel city fc';
                    break;
                case 'bluejays':
                    teamKey = 'blue jays';
                    break;
                case 'mapleleafs':
                    teamKey = 'maple leafs';
                    break;
                default:
                    break;
            }
        }
        const key = 'leaderboards/' + sportKey + '/' + teamKey + '/' + auth.currentUser.uid;
        const lastEntry = ls['last-entry'];
        let lastEntryDate = lastEntry ? moment(lastEntry).add(1, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss.SSSSSS") : null;
        if (!lastEntryDate) {
            const twoDaysAgo = moment().subtract(2, 'days').startOf('day').format("YYYY-MM-DD HH:mm:ss.SSSSSS")
            lastEntryDate = twoDaysAgo;
        }
        const updates =
        {
            [key]: {
                "correct": streak ? true : false,
                "numGuesses": streak ? 4 : null,
                'expiry': lastEntryDate,
                'version': "NotNeededYet",
                "game_duration": 60,
                "streak": streak,
                "wins": wins ?? 0,
                "losses":
                    losses ?? 0
            }
        };

        try {
            console.log(updates)
            await update(ref(database), updates);
        } catch (e) {
            //ignore
        }
    }

    return [record, streak, ls];
}

export const getRecord = async function (ls, gameType, database, uid) {
    if (!ls && gameType) {
        ls = await getAllTimeNumbersDb(gameType, database, uid)
    }

    if (ls && (await getTotalWins(ls, null, database, uid) > 0 || await getTotalLosses(ls, null, database, uid) > 0)) {
        return await getTotalWins(ls, null, database, uid) + '-' + await getTotalLosses(ls, null, database, uid);
    } else {
        return null;
    }
}

export const getStreak = async function (ls, gameType, database, uid) {
    if (!ls && gameType) {
        ls = await getAllTimeNumbersDb(gameType, database, uid);
    }

    if (ls) {
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        const lastEntry = ls['last-entry'];
        let lastEntryDatePlusOneDay = lastEntry && new Date(lastEntry);
        if (lastEntryDatePlusOneDay) {
            lastEntryDatePlusOneDay.setDate(lastEntryDatePlusOneDay.getDate() + 1);
        }
        const lastEntryIsYesterday = lastEntryDatePlusOneDay && lastEntryDatePlusOneDay.getTime() === today.getTime();
        return lastEntryIsYesterday || (lastEntry && new Date(lastEntry).getTime() === today.getTime()) ? parseInt(ls['streak']) : 0;
    } else {
        return '';
    }
}

export const getAllTimeNumbersFromLeaderboardJson = function (leaderboard, uid) {
    const expiry = leaderboard[uid] ? leaderboard[uid]['expiry'] : null;
    let lastEntry;
    if (expiry) {
        let expiryDate = moment(expiry);
        lastEntry = expiryDate.subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    } else {
        lastEntry = moment().startOf('day').format("YYYY-MM-DD HH:mm:ss.SSSSSS");
    }
    return { 'total-wins': leaderboard[uid]['wins'], 'total-losses': leaderboard[uid]['losses'], 'streak': leaderboard[uid]['streak'], 'last-entry': lastEntry };

}

export const getAllTimeNumbersDb = async function (gameType, database, uid) {
    const _data = ref(database, allTimeNumbersRoute(gameType, uid));
    return get(_data).then(snapshot => {
        const data = snapshot.val();
        if (data) {
            return typeof data === "object" ? data : JSON.parse(data);;
        }
    });
}

export const cleanName = function (teamName, lowerCase) {
    teamName = teamName
        .replaceAll("/", "")
        .replaceAll(".", "")
        .replaceAll("é", "e")
        .replaceAll("í", "i")
        .replaceAll("á", "a")
        .replaceAll("à", "a");

    if (lowerCase) {
        teamName = teamName.toLowerCase();
    }
    return teamName;
}

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

export const luminance = function (r, g, b) {
    var a = [r, g, b].map((v) => {
        v /= 255;
        return v <= 0.03928
            ? v / 12.92
            : Math.pow((v + 0.055) / 1.055, GAMMA);
    });
    return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

export const contrast = function (rgb1, rgb2) {
    var lum1 = luminance(...rgb1);
    var lum2 = luminance(...rgb2);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
}

export const hexToRgb = function (hex) {
    if (hex.length === 3) hex = hex.split("").map(function (hexVal) {
        return hexVal + hexVal;
    }).join('');
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)]
        : null;
}