import React from 'react';
import { withRouter } from 'react-router-dom';

const SiteDown = (props) => {

    return (
        <div className="site-down-container">
            <div className="h-100 d-flex align-items-center justify-content-center">
                <div className="site-down">
                    <h1>Site is down for roster updates.</h1><h4>It will be back at midnight in your local timezone.</h4>
                </div>
            </div>
        </div>
    );

}
export default withRouter(SiteDown);
