import { createBrowserHistory } from "history";

const history = createBrowserHistory;

const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const initialState = {
};

export const navActionCreators = {
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        default:
            return state;
    }
};
