import React from 'react';
import { connect } from 'react-redux';
import { ageByDateOfBirth, birthdayIsToday, convertToImperial, convertToMetric } from '../../helpers/functions';
import { Tooltip } from '@mui/material';

let imperialLocalStorage = localStorage.getItem('imperial');

class ResultModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imgLoaded: false,
            imgError: false,
            imperial: imperialLocalStorage === undefined || imperialLocalStorage !== 'false',
            birthdayTooltipIsOpen: false
        }

        this.setImageLoaded = this.setImageLoaded.bind(this);
        this.setImageError = this.setImageError.bind(this);
    }

    componentDidMount() {
        imperialLocalStorage = localStorage.getItem('imperial');
        this.setState({ imperial: imperialLocalStorage === undefined || imperialLocalStorage !== 'false' })
    }

    setImageLoaded() {
        this.setState({ imgLoaded: true })
    }

    setImageError() {
        this.setState({ imgError: true })
    }

    render() {
        const { player } = this.props;
        const teamLabel = (teamName) => {
            const teamNames = teamName.split(" ");
            let capitalized = "";
            teamNames.forEach(n => {
                capitalized += n.charAt(0).toUpperCase() + n.slice(1) + " ";
            })
            return capitalized.trim();
        }

        return (
            <div className={`player-data ${this.props.league.replaceAll(" ", "")}${this.state.imgError ? ' img-error' : ''}`}>
                <div>
                    {player.img && !this.state.imgLoaded && !this.state.imgError &&
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border text-secondary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    {player.img && !this.state.imgError && <><img src={player.img} className={`player-shot${["NBA", "WNBA", "NHL", "MLS"].includes(this.props.league) ? ' big' : ''}`} alt={player.name} onLoad={this.setImageLoaded} onError={this.setImageError} style={!this.state.imgLoaded ? { display: 'none' } : {}} />{birthdayIsToday(player) && <Tooltip open={this.state.birthdayTooltipIsOpen} onOpen={() => this.setState({ birthdayTooltipIsOpen: true })} onClose={() => this.setState({ birthdayTooltipIsOpen: false })} enterTouchDelay={0} title="Today is their birthday!"><span className="birthday" onClick={() => this.setState({ birthdayTooltipIsOpen: true })} /></Tooltip>}</>}
                </div>
                <div>
                    <h2 className="player-name">{player.name.includes(" ") ? <><span>{player.name.slice(0, player.name.indexOf(" "))}</span> {player.name.slice(player.name.indexOf(" "))}</> : player.name}</h2>
                    <hr />
                    <h4 className="player-minor sport">{player.sport != null && player.sport != '' && player.sport != 'null' && player.sport != '--' && player.sport != 'N/A' ?
                        <><span>{player.sport}</span>{player.team != null && player.team != '' && player.team != 'null' && player.team != '--' ? <span> ({teamLabel(player.team)})</span> : ''}</> : ''}</h4>
                    <h4 className="player-minor">{player.number != null && player.number != '' && player.number != 'null' && player.number != '--' && player.number != 'N/A' ?
                        <><span>#</span><span className="player-number">{player.number}</span>{player.position != null && player.position != '' && player.position != 'null' && player.position != '--' && player.position != 'N/A' ? <span className="dot">  •  </span> : ''}</> : ''}{player.position != null && player.position != '' && player.position != 'null' && player.position != '--' && player.position != 'N/A' ? <span className="player-position">{player.position}</span> : ''}</h4>
                    <h4 className="player-minor">{![null, 'null', '', '--', 'N/A'].includes(ageByDateOfBirth(player)) ?
                        <><span>Age: </span>{ageByDateOfBirth(player)}{(player.experience ?? player.exp) != null && (player.experience ?? player.exp) != '' && (player.experience ?? player.exp) != 'null' && (player.experience ?? player.exp) != '--' && (player.experience ?? player.exp) != 'N/A' ? <span className="dot">  •  </span> : ''}</> : ''}{(player.experience ?? player.exp) != null && (player.experience ?? player.exp) != '' && (player.experience ?? player.exp) != 'null' && (player.experience ?? player.exp) != '--' && (player.experience ?? player.exp) != 'N/A' ? <><span>Exp: </span>{player.experience ?? player.exp}</> : ''}</h4>
                    <h4 className="player-minor">{player.height && player.height != 'null' && player.height != '--' && player.height != 'N/A' ?
                        <><span>Ht: </span>{["Prem", "La Liga"].includes(this.props.league) && this.state.imperial ? convertToImperial(player.height, 'height') : !["Prem", "La Liga"].includes(this.props.league) && !this.state.imperial ? convertToMetric(player.height, 'height') + 'cm' : (`${player.height}${["Prem", "La Liga"].includes(this.props.league) && !this.state.imperial ? 'cm' : ''}`)}{player.weight != null && player.weight != '' && player.weight != 'null' && player.weight != '--' && player.weight != 'N/A' ? <span className="dot">  •  </span> : ''}</> : ''}{player.weight && player.weight != 'null' && player.weight != '--' && player.weight != 'N/A' ? <><span>Wt: </span>{["Prem", "La Liga"].includes(this.props.league) && this.state.imperial ? convertToImperial(player.weight, 'weight') : !["Prem", "La Liga"].includes(this.props.league) && !this.state.imperial ? convertToMetric(player.weight, 'weight') : player.weight}</> : ''}</h4>
                    <h4 className="player-minor">{player.bat && player.bat != 'null' && player.bat != '--' && player.bat != 'N/A' ?
                        <><span>Bat: </span>{player.bat}{player.throw && player.throw != 'null' && player.throw != '--' ? <span className="dot">  •  </span> : ''}</> : ''}{player.throw && player.throw != 'null' && player.throw != '--' ? <><span>Throw: </span>{player.throw}</> : ''}</h4>
                    <h4 className="player-minor">{player.shot && player.shot != 'null' && player.shot != '--' && player.shot != 'N/A' ?
                        <><span>Shot: </span>{`${player.shot}`}</> : ''}</h4>
                    <h4 className="player-minor">{player.college && player.college != 'null' && player.college != '--' && player.college != 'N/A' ?
                        <><span>College: </span>{`${player.college}`}</> : ''}</h4>
                    <h4 className="player-minor">{player.nationality && player.nationality != 'null' && player.nationality != '--' && player.nationality != 'N/A' ?
                        <><span>Nationality: </span>{`${player.nationality}`}</> : ''}</h4>
                </div>
            </div>
        );
    }
}
export default connect()(ResultModal);
