let abortController = new window.AbortController();
let signal = abortController ? abortController.signal : "";

const request = (url, method, headers, body, params) => {
    let config = {
        method: method,
    }
    if (headers) {
        config.headers = headers;
    }
    if (body) {
        config.body = JSON.stringify(body)
    }
    if (signal && signal != "" && signal.aborted === true) {
        //Refresh the abortController because you can't reset the abort property of a signal
        abortController = new window.AbortController();
        signal = abortController ? abortController.signal : "";
    }
    if (signal && signal != "") {
        config.signal = signal;
    }
    return fetch(url + params, config)
        .then(response => {
            if (response.status === 204) {
                //Some endpoints do not return anything if there is no data to be returned. So NO_CONTENT must be specified as it is not always an error
                throw [NO_CONTENT, 'No Content'];
            } else if (response.status > 399) {
                //There was a request failure. Return the response. Do not try to convert it to a JSON object as there is no guarantee that it is formatted as a json
                return (response.text()
                    .then(text => {
                        console.log(text);
                        return Promise.reject([REQUEST_FAILURE, text])
                    })
                )
            }
            return response

        })
        .then(response =>
            //Our request is successful. Convert it to JSON
            response.json().then(data => ({ data, response }))
        )
        .then(({ data, response }) => {
            return [REQUEST_SUCCESS, data, response.headers]
        })
        .catch(err => err);
}

const baseURL = 'https://cameronjones.tech/message/api/'
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const sportleMetadataSuccess = 'SPORTLE_METADATA_SUCCESS';
const sportleMetadataFailure = 'SPORTLE_METADATA_FAILURE';

const teamsListSuccess = 'TEAMS_LIST_SUCCESS';
const teamsListFailure = 'TEAMS_LIST_FAILURE';

const playersListSuccess = 'PLAYERS_LIST_SUCCESS';
const playersListFailure = 'PLAYERS_LIST_FAILURE';

const conferencesListSuccess = 'CONFERENCES_LIST_SUCCESS';
const conferencesListFailure = 'CONFERENCES_LIST_FAILURE';

const initialState = {
    sportleMetadata: {},
    sportleMetadataError: '',
    teamsList: [],
    teamsListError: '',
    playersList: [],
    playersListError: '',
    conferencesList: [],
    conferencesListError: '',
};

export const sportleDataActionCreators = {
    receiveSportleMetadata: (result) => ({
        type: sportleMetadataSuccess,
        sportleMetadata: result
    }),

    sportleMetadataError: (message) => ({
        type: sportleMetadataFailure,
        sportleMetadata: {},
        sportleMetadataError: message
    }),

    getSportleMetadata: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return request(baseURL + 'GetSportleMetadata?date=' + new Date().toLocaleString("en-US").replace(",", ""), 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sportleDataActionCreators.sportleMetadataError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(sportleDataActionCreators.receiveSportleMetadata(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sportleDataActionCreators.receiveSportleMetadata([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveTeamsList: (result) => ({
        type: teamsListSuccess,
        teamsList: result
    }),

    teamsListError: (message) => ({
        type: teamsListFailure,
        teamsList: [],
        teamsListError: message
    }),

    getTeamsList: (version, sport) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return request(baseURL + 'GetSportleTeams', 'GET', headers, null, '?date=' + new Date().toLocaleString("en-US").replace(",", "") + '&version=' + version + '&sport=' + sport)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sportleDataActionCreators.teamsListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(sportleDataActionCreators.receiveTeamsList(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sportleDataActionCreators.receiveTeamsList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receivePlayersList: (result) => ({
        type: playersListSuccess,
        playersList: result
    }),

    playersListError: (message) => ({
        type: playersListFailure,
        playersList: [],
        playersListError: message
    }),

    getPlayersList: (version, sport, team) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return request(baseURL + 'GetSportlePlayers', 'GET', headers, null, '?date=' + new Date().toLocaleString("en-US").replace(",", "") + '&version=' + version + '&sport=' + sport + (team ? '&team=' + team : ''))
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sportleDataActionCreators.playersListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(sportleDataActionCreators.receivePlayersList(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sportleDataActionCreators.receivePlayersList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveConferencesList: (result) => ({
        type: conferencesListSuccess,
        conferencesList: result
    }),

    conferencesListError: (message) => ({
        type: conferencesListFailure,
        conferencesList: [],
        conferencesListError: message
    }),

    getConferencesList: (version, sport) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return request(baseURL + 'GetSportleConferences', 'GET', headers, null, '?date=' + new Date().toLocaleString("en-US").replace(",", "") + '&version=' + version + '&sport=' + sport)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(sportleDataActionCreators.conferencesListError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(sportleDataActionCreators.receiveConferencesList(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(sportleDataActionCreators.receiveConferencesList([]))
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case sportleMetadataSuccess:
            return Object.assign({}, state, {
                sportleMetadata: action.sportleMetadata,
                sportleMetadataError: ''
            })
        case sportleMetadataFailure:
            return Object.assign({}, state, {
                sportleMetadataError: action.sportleMetadataError
            })
        case teamsListSuccess:
            return Object.assign({}, state, {
                teamsList: action.teamsList,
                teamsListError: ''
            })
        case teamsListFailure:
            return Object.assign({}, state, {
                teamsListError: action.teamsListError
            })
        case playersListSuccess:
            return Object.assign({}, state, {
                playersList: action.playersList,
                playersListError: ''
            })
        case playersListFailure:
            return Object.assign({}, state, {
                playersListError: action.playersListError
            })
        case conferencesListSuccess:
            return Object.assign({}, state, {
                conferencesList: action.conferencesList,
                conferencesListError: ''
            })
        case conferencesListFailure:
            return Object.assign({}, state, {
                conferencesListError: action.conferencesListError
            })
        default:
            return state
    }
};