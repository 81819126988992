import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFootballBall, faBasketballBall, faHockeyPuck, faBaseballBatBall, faSoccerBall } from '@fortawesome/free-solid-svg-icons'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import moment from 'moment';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visited: false,
            isDropdownOpened: false,
            pathname: '',
            showWorldSeriesModal: !localStorage.getItem('hide2024WorldSeriesModal') && moment().isBefore(moment('2024-11-01'))
        }

        this.mousedownListener = this.mousedownListener.bind(this);
        this.handleClosingLoginOnOffClick = this.handleClosingLoginOnOffClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.allTimeNumbersUpdatePrompt = this.allTimeNumbersUpdatePrompt.bind(this);
        this.toggleWorldSeriesModal = this.toggleWorldSeriesModal.bind(this);
    }

    componentDidMount() {
        const h = localStorage.getItem('visited-home');
        if (h) { this.setState({ visited: true }) } else {
            localStorage.setItem('visited-home', 'true');
        }
        document.body.classList = "home";

        const pathName = this.props.location.pathname;
        this.setState({ pathName: pathName })

        document.addEventListener('mousedown', this.mousedownListener);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.mousedownListener)
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(prevState => ({
                isDropdownOpened: false,
                pathName: this.props.location.pathname
            }));
        }
    }

    mousedownListener = (e) => {
        if (this.state.isDropdownOpened) {
            const profileDropdownContainer = document.getElementsByClassName('dropdown-container footer')[0];
            const loginBox = profileDropdownContainer.getElementsByClassName("profile-dropdown")[0];
            const profileLink = profileDropdownContainer.getElementsByClassName("profile")[0];
            if ((!loginBox || (loginBox !== e.target && !loginBox.contains(e.target))) &&
                (!profileLink || (profileLink !== e.target && !profileLink.contains(e.target)))) {
                this.handleClosingLoginOnOffClick();
            }
        }
    }

    handleClosingLoginOnOffClick = () => {
        if (this.state.isDropdownOpened) {
            this.setState({ isDropdownOpened: false });
        }
    }

    handleClick = () => {
        this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
    }

    allTimeNumbersUpdatePrompt(cntnue) {
        this.setState({ runningUpdate: true }, async () => {
            await this.props.allTimeNumbersUpdatePrompt(cntnue);
            this.setState({ runningUpdate: false })
        })
    }

    toggleWorldSeriesModal() {
        this.setState({ showWorldSeriesModal: false }, () => {
            localStorage.setItem('hide2024WorldSeriesModal', 'true');
        })
    }

    render() {
        const { visited } = this.state;
        const size = window.innerWidth <= 850 ? 'sm' : 'lg';

        return (
            <div className="home" >
                {
                    this.props.allTimeNumbersUpdatePrompt &&
                    <Modal isOpen={true} toggle={() => this.allTimeNumbersUpdatePrompt(false)}>
                        <ModalHeader toggle={() => this.allTimeNumbersUpdatePrompt(false)}>Update Records/Streaks</ModalHeader>
                        <ModalBody>
                            <h6>
                                We've found all-time records and/or streaks on this browser from previous sessions.
                            </h6>
                            <h6>
                                Click Continue to transfer these records/streaks to this account, or click Cancel to ignore all such data.
                            </h6>
                        </ModalBody>
                        <ModalFooter>
                            {
                                this.state.runningUpdate ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                    :
                                    <>
                                        <Button color="primary" onClick={() => this.allTimeNumbersUpdatePrompt(true)}>Continue</Button>
                                        <Button color="secondary" onClick={() => this.allTimeNumbersUpdatePrompt(false)}>Cancel</Button>
                                    </>
                            }
                        </ModalFooter>
                    </Modal>
                }
                {
                    this.state.showWorldSeriesModal &&
                    <Dialog
                        className="app-store"
                        onClose={this.toggleWorldSeriesModal}
                        open={true}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        TransitionComponent={Transition}>
                        <DialogTitle id="alert-dialog-title">MLB World Series</DialogTitle>
                        <DialogContent className="modal-body">
                            <DialogContentText id="alert-dialog-description">
                                Celebrate the 2024 World Series by testing your knowledge of the Yankees and Dodgers!
                            </DialogContentText>
                            <br />
                            <DialogContentText id="alert-dialog-description">
                                Press 'MLB' and select '2024 World Series (NYY - LAD)' from the list of teams to compete with others in solving the daily mystery player from the Yankees and Dodgers combined rosters! Enjoy!
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <MuiButton color="primary" onClick={this.toggleWorldSeriesModal}>Close</MuiButton>
                        </DialogActions>
                    </Dialog>
                }
                <div className="home-links">
                    <Link to="/NFL" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn nfl`}><span>NFL</span><FontAwesomeIcon icon={faFootballBall} color="#5d1114" size={"lg"} /></Link>
                    <Link to="/NBA" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn nba`}><span>NBA</span><FontAwesomeIcon icon={faBasketballBall} color="#b7572c" size={"lg"} /></Link>
                    <Link to="/WNBA" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn wnba`}><span>WNBA</span><FontAwesomeIcon icon={faBasketballBall} color="#b7572c" size={"lg"} /></Link>
                    <Link to="/NHL" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn nhl`}><span>NHL</span><FontAwesomeIcon icon={faHockeyPuck} color="white" size={"lg"} /></Link>
                    <Link to="/MLB" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn mlb`}><span>MLB</span><FontAwesomeIcon icon={faBaseballBatBall} color="#c07b61" size={"lg"} /></Link>
                    <Link to="/MLS" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn mls`}><span>MLS</span><FontAwesomeIcon icon={faSoccerBall} size={"lg"} /></Link>
                    <Link to="/nwsl" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn nwsl`}><span>NWSL</span><FontAwesomeIcon icon={faSoccerBall} size={"lg"} /></Link>
                    <Link to="/premier-league" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn prem`}><span>Premier League</span><FontAwesomeIcon icon={faSoccerBall} size={size} /></Link>
                    <Link to="/la-liga" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn laliga`}><span>La Liga</span><FontAwesomeIcon icon={faSoccerBall} size={"lg"} /></Link>
                    <Link to="/all" className={`btn ${visited ? 'ignore-animation ' : ''}nav-link-btn all`}><span>All Sports</span>
                        <span className="all-icons">
                            <FontAwesomeIcon icon={faSoccerBall} size={"sm"} />
                            <FontAwesomeIcon icon={faBaseballBatBall} color="#c07b61" size={"sm"} />
                            <FontAwesomeIcon icon={faHockeyPuck} color="white" size={"sm"} />
                            <FontAwesomeIcon icon={faBasketballBall} color="#b7572c" size={"sm"} />
                            <FontAwesomeIcon icon={faFootballBall} color="#5d1114" size={"sm"} />
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}
export default withRouter(Home);
