import React from 'react';

const AllTimeNumbers = (props) => {

    return (
        <div className={`all-time-numbers${props.bottom ? ' bottom' : ''}`}>
            <span>Current Streak: <b>{props.streak ? (props.streak + ' day' + (props.streak != 1 ? 's' : '')) : '0 days'}</b></span>
            <br />
            <span>All-Time: <b>{props.allTimeRecord || '0-0'}</b></span>
        </div>
    );
}

export default AllTimeNumbers;