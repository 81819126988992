import React from 'react';
import { ButtonDropdown, Container, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSignOut, faSun, faUser, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { auth } from '../firebase';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { AppBar, Button, IconButton, Tooltip } from '@mui/material';

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDropdownOpened: false,
            pathname: '',
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        const pathName = this.props.location.pathname;
        this.setState({ pathName: pathName })
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.setState(prevState => ({
                isDropdownOpened: false,
                pathName: this.props.location.pathname
            }));
        }
    }

    handleClick = () => {
        this.setState({ isDropdownOpened: !this.state.isDropdownOpened });
    }

    render() {
        return (
            <>
                <AppBar position='relative' color="transparent" elevation={6}>
                    <div className={`home-header${this.props.loading ? ' loading' : ''}`} style={{ justifyContent: 'space-between' }}>
                        <div>
                            <Tooltip title="Change Theme">
                                <IconButton className="theme-button" onClick={this.props.toggleDarkTheme}>
                                    <FontAwesomeIcon className="theme" icon={this.props.theme === 'dark' ? faSun : faMoon} />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div>
                            <Link to="/"><h1>Sportle</h1></Link>
                        </div>
                        {auth.currentUser ?
                            <ButtonDropdown isOpen={this.state.isDropdownOpened} toggle={this.handleClick} className="profile-actions">
                                <DropdownToggle className="profile-toggler">
                                    <Tooltip enterDelay={1000} title="Menu">
                                        <IconButton className="theme-button" onClick={this.handleClick}>
                                            <FontAwesomeIcon className="profile" icon={faUser} />
                                        </IconButton>
                                    </Tooltip>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    {window.location.pathname !== '/profile' &&
                                        <Button className="dropdown-item" onClick={() => this.props.history.push('/profile')} startIcon={<FontAwesomeIcon icon={faUserCircle} />}>
                                            Profile
                                        </Button>
                                    }
                                    <Button className="dropdown-item" onClick={async () => await this.props.signOut()} startIcon={<FontAwesomeIcon icon={faSignOut} />}>
                                        Logout
                                    </Button>
                                </DropdownMenu>
                            </ButtonDropdown>

                            : <div className="invisible">
                                <ButtonDropdown isOpen={false} className="profile-actions">
                                    <DropdownToggle className="profile-toggler">
                                        <Tooltip enterDelay={1000} title="Menu">
                                            <IconButton className="theme-button" onClick={null}>
                                                <FontAwesomeIcon className="profile" icon={faUser} />
                                            </IconButton>
                                        </Tooltip>
                                    </DropdownToggle>
                                    <DropdownMenu right />
                                </ButtonDropdown>
                            </div>
                        }
                    </div>
                </AppBar>
                <Container>
                    {this.props.children}
                    <ToastContainer style={{ textAlign: "center" }}
                        position="bottom-right"
                        hideProgressBar={false}
                        icon={false}
                        autoClose={10000}
                        newestOnTop={false}
                        closeOnClick={false}
                        draggable={false}
                        rtl={false}
                    />
                </Container>
            </>
        )
    }
}

export default Layout;