import React, { useEffect, useRef, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, InputLabel, Input } from '@mui/material';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { updatePassword, validatePassword, updateProfile } from 'firebase/auth';
import { auth } from '../../firebase';
import { ref, update, set } from "firebase/database";
import { profanity } from '../../helpers/profanity';
import '../../stylesheets/sign-in.css';
import { Cancel, Check, Edit } from '@mui/icons-material';

const validRegex = RegExp(/^[a-zA-Z0-9-_\.\s]+$/);

let active = false;
let currentX;
let initialX;
let xOffset = 0;
let dragWidth;

const Profile = (props) => {
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [message, setMessage] = useState('');
    const [nameMsg, setNameMsg] = useState('');
    const [deleteMsg, setDeleteMsg] = useState('');
    const [savingName, setSavingName] = useState('');
    const [submitting, setSubmitting] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editDisplayName, setEditDisplayName] = useState(false);
    const [editedDisplayName, setEditedDisplayName] = useState('');
    const container = useRef();
    const dragItem = useRef();
    const inactiveSlider = useRef();
    const activeSlider = useRef();

    useEffect(() => {
        document.body.classList = 'home';

        return (function () {
            container.current?.removeEventListener("touchstart", dragStart);
            container.current?.removeEventListener("touchend", dragEnd);
            container.current?.removeEventListener("touchmove", drag);

            container.current?.removeEventListener("mousedown", dragStart);
            container.current?.removeEventListener("mouseup", dragEnd);
            container.current?.removeEventListener("mousemove", drag);
        })();

    }, []);

    useEffect(() => {
        if (editDisplayName) {
            dragWidth = container.current.clientWidth - dragItem.current.clientWidth - 5;

            container.current.addEventListener("touchstart", dragStart, false);
            container.current.addEventListener("touchend", dragEnd, false);
            container.current.addEventListener("touchmove", drag, false);

            container.current.addEventListener("mousedown", dragStart, false);
            container.current.addEventListener("mouseup", dragEnd, false);
            container.current.addEventListener("mousemove", drag, false);
        }

    }, [editDisplayName])

    const dragStart = (e) => {
        dragWidth = container.current.clientWidth - dragItem.current.clientWidth - 5;
        if (e.type === "touchstart") {
            initialX = e.touches[0].clientX - xOffset;
        } else {
            initialX = e.clientX - xOffset;
        }

        if (e.target === dragItem.current) {
            active = true;
        }
    }

    const dragEnd = (e) => {
        if (currentX < dragWidth) {
            animateBack();
        } else {
            saveDisplayName();
        }

        initialX = currentX;
        active = false;
    }

    const drag = (e) => {
        if (active) {

            e.preventDefault();

            if (e.type === "touchmove") {
                currentX = e.touches[0].clientX - initialX;
            } else {
                currentX = e.clientX - initialX;
            }

            // xOffset = currentX;

            if (currentX > 0 && currentX < dragWidth) {
                setTranslate(currentX, dragItem.current);
            }
        }
    }

    const setTranslate = (xPos, el) => {
        el.style.transform = "translate3d(" + xPos + "px, " + 0 + "px, 0)";
        inactiveSlider.current.style.width = "calc(100% - " + xPos + "px)";
        activeSlider.current.style.width = "calc(0% + " + xPos + "px)";
        if (xPos > dragWidth / 2) {
            activeSlider.current.style.zIndex = 2;
        } else {
            activeSlider.current.style.zIndex = 0;
        }
    }

    const animateBack = () => {
        dragItem.current.classList.toggle('animate');
        setTranslate(0, dragItem.current);
        setTimeout(() => {
            dragItem.current.classList.toggle('animate');
        }, 600);
    }

    const handleError = (error) => {
        let newCode;
        if (error.code && error.code == "unknown" && error.message != null) {
            newCode = error.message.replaceAll("(auth/", "").replaceAll(")", "");
        } else {
            error.code = error.code?.replaceAll("auth/", "")?.replaceAll(")", "");
        }

        switch (newCode ?? error.code) {
            case "ERROR_EMAIL_ALREADY_IN_USE":
            case "account-exists-with-different-credential":
            case "email-already-in-use":
                return "Email already used.";

            case "ERROR_WRONG_PASSWORD":
            case "wrong-password":
            case "invalid-credential":
                return "Wrong email/password combination.";

            case "weak-password":
                return "Password is too weak.";

            case "ERROR_USER_NOT_FOUND":
            case "user-not-found":
                return "No user found with this email.";

            case "ERROR_USER_DISABLED":
            case "user-disabled":
                return "User disabled.";

            case "ERROR_TOO_MANY_REQUESTS":
            case "operation-not-allowed":
                return "Too many requests to log into this account.";

            case "ERROR_OPERATION_NOT_ALLOWED":
            case "operation-not-allowed":
                return "Server error, please try again later.";

            case "ERROR_INVALID_EMAIL":
            case "invalid-email":
                return "Email address is invalid.";

            case "canceled":
                return null;

            case "weak-password":
                return "Weak password.";

            case "requires-recent-login":
                return "For security purposes, your most recent login is too old. Please logout, log back in, and try again.";

            default:
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
                return "Password change failed. Please try again.";
        }
    }

    const toggleEditDisplayName = () => {
        setEditDisplayName(!editDisplayName);
        setEditedDisplayName(auth.currentUser.displayName);
        setNameMsg('');
    }

    const hasProfanity = (inputString) => {
        let isProfane = false;
        profanity.forEach((word) => {
            if (inputString.toLowerCase().split(' ').includes(word)) {
                isProfane = true;
            }
        });
        return isProfane;
    }

    const censor = (inputString) => {
        const inputStringSoup = inputString.split(' ');
        profanity.forEach((word) => {
            for (let i = 0; i < inputStringSoup.length; i++) {
                if (inputStringSoup[i].toLowerCase() == word) {
                    inputStringSoup[i] = inputStringSoup[i].replace(/./g, '*');
                }
            }
        });
        return inputStringSoup.join(' ');
    }

    const saveDisplayName = async () => {
        if (!editedDisplayName) {
            setNameMsg('Please provide a display name');
            return;
        }
        if (editedDisplayName.endsWith(' ')) {
            setNameMsg('Display names must not end in a space');
            return;
        }
        if (!editedDisplayName.match(/^[a-zA-Z0-9]/)) {
            setNameMsg('Display names must start with a letter or number');
            return;
        }
        if (!editedDisplayName.match(/[a-zA-Z].*[a-zA-Z]/)) {
            setNameMsg('Display names must contain at least two letters');
            return;
        }
        if (editedDisplayName.includes('  ')) {
            setNameMsg('Display names cannot contain multiple consecutive spaces');
            return;
        }
        if (editedDisplayName.includes('--')) {
            setNameMsg('Display names cannot contain multiple consecutive dashes');
            return;
        }
        if (editedDisplayName.includes('__')) {
            setNameMsg(
                'Display names cannot contain multiple consecutive underscores');
            return;
        }
        if (editedDisplayName.includes('..')) {
            setNameMsg(
                'Display names cannot contain multiple consecutive periods');
            return;
        }
        if (!editedDisplayName.match(validRegex)) {
            setNameMsg(
                'Display name may only contain letters, numbers, periods, spaces, dashes, and underscores');
            return;
        }
        if (editedDisplayName.length >= 40) {
            setNameMsg("Please provide a display name shorter than 40 characters");
            return;
        }
        if (editedDisplayName.length <= 1) {
            setNameMsg("Please provide a display name longer than 1 character");
            return;
        }
        if (editedDisplayName === "N/A") {
            setNameMsg("Please provide a valid display name");
            return;
        }
        const containsProfanity = hasProfanity(editedDisplayName);
        if (containsProfanity) {
            const cleanString = censor(editedDisplayName);
            setNameMsg("Display Names cannot contain profanity: " + cleanString);
            return;
        }
        setNameMsg('');
        setSavingName(true);

        try {
            await updateProfile(auth.currentUser, { displayName: editedDisplayName });
            setEditedDisplayName('');
            setSavingName(false);
            setEditDisplayName(false);
            setNameMsg("Display name successfully updated.");
            let updates = {};
            updates[`users/${auth.currentUser.uid}`] = {
                "display_name": editedDisplayName
            };
            try {
                await update(ref(props.database), updates);
            }
            catch (e) {
                //ignore
            }
        } catch (e) {
            console.log(e);
            setNameMsg(handleError(e));
            setSavingName(false);
        }
    }

    const onSubmit = async (e) => {
        if (e) e.preventDefault();
        setSubmitting(true);
        setMessage('');

        if (!password) {
            setMessage("Please provide a new password.");
            return;
        }
        if (!confirmPassword) {
            setMessage("New Password/Confirm New Password must match.");
            return;
        }

        await validatePassword(auth, password).then(async resp => {
            if (!resp.isValid) {
                if (!resp.meetsMinPasswordLength) {
                    setMessage('Password must be at least ' + resp.passwordPolicy.customStrengthOptions.minPasswordLength + ' characters');
                    setSubmitting(false);
                    return;
                }
                if (!resp.meetsMaxPasswordLength) {
                    setMessage('Password must be a max of ' + resp.passwordPolicy.customStrengthOptions.maxPasswordLength + ' characters');
                    setSubmitting(false);
                    return;
                }
            }

            try {
                await updatePassword(auth.currentUser, password);
                setMessage('Successfully updated password.');
                setPassword('');
                setConfirmPassword('');
                setSubmitting(false);
            } catch (e) {
                console.log(e);
                setMessage(handleError(e));
                setSubmitting(false);
            }
        });
    }

    const deleteAccount = async () => {
        setDeleteMsg('');
        setDeleting(true);
        try {
            await set(ref(props.database, `guesses/${auth.currentUser.uid}`), null);
            await set(ref(props.database, `users/${auth.currentUser.uid}`), null);
            await auth.currentUser.delete();
        } catch (e) {
            setDeleteMsg(handleError(e));
            setDeleting(false);
        }
    }

    return (
        <main>
            <section>
                {deleteModal &&
                    <Modal isOpen={true} toggle={() => setDeleteModal(false)}>
                        <ModalHeader toggle={() => setDeleteModal(false)}>Delete Account</ModalHeader>
                        <ModalBody>
                            <h6>
                                Deleting your account cannot be undone and will result in all data being lost, including any records and streaks. Are you sure you would like to continue?
                            </h6>
                            {deleteMsg && <h7>{deleteMsg}</h7>}
                        </ModalBody>
                        <ModalFooter>
                            {
                                deleting ? <div className="spinner-border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                    :
                                    <>
                                        <Button color="primary" onClick={deleteAccount}>Continue</Button>
                                        <Button color="secondary" onClick={() => setDeleteModal(false)}>Cancel</Button>
                                    </>
                            }
                        </ModalFooter>
                    </Modal>
                }
                <div className="sign-in">
                    <div className="home-wrapper">
                        <div className="sign-in-form profile">
                            <h2>Profile</h2>
                            <hr style={{ marginTop: '0px' }} />
                            <div className="personal-info">
                                <h5>Personal Information</h5>
                                <div className={editDisplayName ? 'margin-bottom' : ''}>
                                    <b>Email: </b><span style={{ fontWeight: '100' }}>{auth.currentUser.email}</span>
                                </div>
                                <div>
                                    <b>Display Name: </b>
                                    {!editDisplayName ?
                                        <>
                                            <span className='display-name' style={{ fontWeight: '100' }}>{auth.currentUser.displayName ?? 'N/A'}</span>
                                            <IconButton className="edit" size="small" onClick={toggleEditDisplayName}><Edit htmlColor="#ff8e3a" /></IconButton>
                                        </>
                                        :
                                        <div className="display-name-input">
                                            <Input
                                                placeholder='Display Name'
                                                id="edited-display-name"
                                                type={"text"}
                                                onChange={(e) => setEditedDisplayName(e.target.value)}
                                                value={editedDisplayName}
                                            />
                                            {!savingName && <IconButton disabled={savingName} className="slider-cancel" onClick={toggleEditDisplayName} ><Cancel htmlColor="#FF0000" /></IconButton>}
                                            {savingName ? <div className="spinner-border" role="status" /> :
                                                <>
                                                    <div id="outerContainer">
                                                        <div id="container" ref={container}>
                                                            <div id="item" ref={dragItem}></div>
                                                        </div>
                                                        <div id="container">
                                                            <div id="active-slider" ref={activeSlider}>
                                                                <div id="bar_inner">
                                                                    <span className="slider-drag">Slide to save</span>
                                                                </div>
                                                            </div>
                                                            <div id="inactive-slider" ref={inactiveSlider}>
                                                                <div id="bar_inner">
                                                                    <span className="slider-drag">Slide to save</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="hide-mobile">
                                                        <IconButton disabled={savingName} onClick={saveDisplayName} ><Check htmlColor="green" /></IconButton>
                                                        <IconButton disabled={savingName} onClick={toggleEditDisplayName} ><Cancel htmlColor="#FF0000" /></IconButton>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    }
                                    {nameMsg && <p>{nameMsg}</p>}
                                </div>
                            </div>
                            <br />
                            <br className={`${props.signinProvider != 'password' ? '' : 'mobile-d-none'}`} />
                            <div className="change-password">
                                <h5>Change Password</h5>
                                {props.signinProvider != 'password' ?
                                    <p>{`You are not permitted to change your password as you are logged in through ${props.signinProvider && props.signinProvider[0].toUpperCase()}${props.signinProvider && props.signinProvider.substring(1).replaceAll(".com", "")}`}</p> :
                                    <>

                                        <form onSubmit={(e) => onSubmit(e)}>
                                            <InputLabel htmlFor="new-password">
                                                New Password
                                            </InputLabel>
                                            <Input
                                                placeholder='Password'
                                                id="new-password"
                                                type={
                                                    showPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setShowPassword(!showPassword)
                                                            }
                                                            onMouseDown={() =>
                                                                setShowPassword(!showPassword)
                                                            }
                                                        >
                                                            {showPassword ? (
                                                                <Visibility htmlColor={'white'} />
                                                            ) : (
                                                                <VisibilityOff htmlColor={'white'} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />

                                            <InputLabel htmlFor="confirm-password">
                                                Confirm New Password
                                            </InputLabel>
                                            <Input
                                                placeholder='Confirm New Password'
                                                id="confirm-password"
                                                type={
                                                    showConfirmPassword
                                                        ? "text"
                                                        : "password"
                                                }
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                value={confirmPassword}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            onClick={() =>
                                                                setShowConfirmPassword(!showConfirmPassword)
                                                            }
                                                            onMouseDown={() =>
                                                                setShowConfirmPassword(!showConfirmPassword)
                                                            }
                                                        >
                                                            {showConfirmPassword ? (
                                                                <Visibility htmlColor={'white'} />
                                                            ) : (
                                                                <VisibilityOff htmlColor={'white'} />
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />

                                            <div id="login-container" style={{ marginTop: message ? '10px' : '20px' }}>
                                                {message && <span className={`error-message${message.length > 40 ? ' long' : ''}`}>{message}</span>}
                                                <button disabled={submitting || savingName} type="submit" id="login-btn" className="btn">{submitting ? <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                    : "Change Password"}</button>
                                            </div>
                                        </form>
                                    </>
                                }
                            </div>
                            <div className="delete-account">
                                <button className="btn delete" type="button" onClick={() => setDeleteModal(true)}>Delete Account</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}

export default Profile;