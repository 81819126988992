import React, { Suspense } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getDatabase, ref, onValue, update } from "firebase/database";
import Layout from './components/Layout';
import { auth } from './firebase';
import './custom.css'
import './stylesheets/fonts.css';
import { navActionCreators } from './stores/sportle-nav-store';
import { getRecordAndStreak } from './helpers/functions';
import Profile from './components/profile/profile';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, CssBaseline } from '@mui/material';
import Home from './components/home/home';
import SignIn from './components/home/sign-in';
import NflHome from './components/nfl/nfl-home';
import NbaHome from './components/nba/nba-home';
import WnbaHome from './components/wnba/wnba-home';
import NhlHome from './components/nhl/nhl-home';
import MlbHome from './components/mlb/mlb-home';
import MlsHome from './components/mls/mls-home';
import NwslHome from './components/nwsl/nwsl-home';
import PremHome from './components/prem/prem-home';
import LaLigaHome from './components/laliga/laliga-home';
import AllHome from './components/all/all-home';
import SiteDown from './components/site-down/site-down';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';

let theme = createTheme({
    palette: {
        type: 'light',
    },
});

theme = responsiveFontSizes(theme);

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const database = getDatabase();
let signedIn = auth.currentUser;
let signinProvider = null;
let gotInitialData = false;

// Private route component for auth-only paths. If authstatus == true, show the component.
// Will eventually need to add on to it for Admin-only paths
const PrivateRoute = ({ component: Component, allTimeNumbersUpdatePrompt, getStreamData, users, sportsGuesses, sportsLeaderboards, theme, ...rest }) => {
    return (<Route {...rest} render={(props) => (
        signedIn
            ? <Component {...props} theme={theme} database={database} getStreamData={getStreamData} allTimeNumbersUpdatePrompt={allTimeNumbersUpdatePrompt} signinProvider={signinProvider} users={users} sportsGuesses={sportsGuesses} sportsLeaderboards={sportsLeaderboards} />
            : <Redirect to={'/login'} />

    )
    } />)
}

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            promptUserAllTimeNumbersUpdate: false,
            users: {},
            usersSubscription: null,
            sportsGuesses: {},
            guessesSubscription: null,
            sportsLeaderboards: {},
            leaderboardsSubscription: {},
            showAppStoreRedirect: false,
            showGooglePlayRedirect: false,
            theme: localStorage.getItem('theme') === 'dark' || document.body.getAttribute('theme') === 'dark' ? 'dark' : 'light'
        }

        this.toggleAppStoreRedirect = this.toggleAppStoreRedirect.bind(this);
        this.toggleGooglePlayRedirect = this.toggleGooglePlayRedirect.bind(this);
        this.getStreamData = this.getStreamData.bind(this);
        this.allTimeNumbersUpdatePrompt = this.allTimeNumbersUpdatePrompt.bind(this);
        this.signOut = this.signOut.bind(this);
        this.cancelSubscriptions = this.cancelSubscriptions.bind(this);
        this.toggleDarkTheme = this.toggleDarkTheme.bind(this);
    }

    getCurrentSigninProvider = async () => {
        return !auth.currentUser ? null : auth.currentUser.getIdTokenResult().then((token) => {
            signinProvider = token.signInProvider;
        });
    }

    getStreamData(sport) {
        if (auth.currentUser) {
            if (!sport) {
                if (!this.state.usersSubscription) {
                    const usersStream = ref(database, 'users');
                    const usersSubscription = onValue(usersStream, (snapshot) => {
                        if (snapshot.exists) {
                            const data = snapshot.val();
                            this.setState({ users: data }, () => {
                                gotInitialData = true;
                            });
                        } else {
                            gotInitialData = true;
                        }
                        if (auth.currentUser.displayName != null) {
                            var dbUser = Array.from(Object.entries(this.state.users || {}), ([user, value]) => ({ user, value }))
                                .find((user) => user.user == auth.currentUser.uid);
                            if (dbUser == null || dbUser.value['display_name'] == null) {
                                let updates = {};
                                updates[`users/${auth.currentUser.uid}`] = {
                                    "display_name": auth.currentUser.displayName
                                };
                                try {
                                    update(ref(database), updates);
                                } catch (e) {
                                    //ignore
                                }
                            }
                        }
                    })
                    this.setState({ usersSubscription })
                }

                if (!this.state.guessesSubscription) {
                    const guessesStream = ref(database, 'guesses/' + auth.currentUser.uid);
                    const guessesSubscription = onValue(guessesStream, (snapshot) => {
                        if (snapshot.exists) {
                            const data = snapshot.val();
                            this.setState({ sportsGuesses: data }, () => {
                                gotInitialData = true;
                            });
                        } else {
                            gotInitialData = true;
                        }
                    })
                    this.setState({ guessesSubscription })
                }
            } else {
                if (!this.state.leaderboardsSubscription[sport]) {
                    let leaderboardsSubscription = { ...this.state.leaderboardsSubscription };
                    const leaderboardsStream = ref(database, "leaderboards/" + sport);
                    leaderboardsSubscription[sport] = onValue(leaderboardsStream, (snapshot) => {
                        if (snapshot.exists) {
                            const data = snapshot.val();
                            let sportsLeaderboards = { ...this.state.sportsLeaderboards }
                            sportsLeaderboards[sport] = data;
                            this.setState({ sportsLeaderboards });
                        }
                    })
                    this.setState({ leaderboardsSubscription })
                }
            }
        } else {
            gotInitialData = true;
            this.setState({
                users: {},
                sportsGuesses: {},
                sportsLeaderboards: {}
            });
        }
    }

    async signOut() {
        this.cancelSubscriptions();
        await auth.signOut();
    }

    cancelSubscriptions() {
        if (this.state.usersSubscription) {
            this.state.usersSubscription();
        }
        if (this.state.guessesSubscription) {
            this.state.guessesSubscription();
        }
        if (this.state.leaderboardsSubscription) {
            Object.values(this.state.leaderboardsSubscription).forEach(f => {
                f();
            })
        }
        this.setState({
            usersSubscription: null,
            guessesSubscription: null,
            leaderboardsSubscription: {},
            users: {},
            sportsGuesses: {},
            sportsLeaderboards: {}
        });
    }

    iOS() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    android() {
        const ua = navigator.userAgent.toLowerCase();
        return ua.indexOf("android") > -1;
    }

    componentDidMount() {
        if (localStorage.getItem('theme') === 'dark') {
            document.body.setAttribute('theme', 'dark')
            theme = createTheme({
                palette: {
                    type: 'dark',
                },
            });
        }
        this.setState({ loading: true }, () => {
            const self = this;
            auth.authStateReady().then(() => {
                signedIn = auth.currentUser;
                signinProvider = null;
                self.getCurrentSigninProvider().then(() => {
                    if (self.iOS() && !localStorage.getItem("app-store-redirect")) {
                        self.toggleAppStoreRedirect();
                    } else if (self.android() && !localStorage.getItem("google-play-redirect")) {
                        self.toggleGooglePlayRedirect();
                    }
                    auth.onAuthStateChanged(async function (user) {
                        if (user != null) {
                            signedIn = true;
                            self.getCurrentSigninProvider().then(() => {
                                if (signinProvider !== 'password' || user.emailVerified) {
                                    self.getStreamData();
                                } else {
                                    gotInitialData = true;
                                    self.setState({});
                                }
                            });
                            //reset db numbers with local values if we haven't already
                            if (!localStorage.getItem('updated-db-' + user.uid) && self.state.promptUserAllTimeNumbersUpdate === false) {
                                const ls = localStorage;
                                if (Object.keys(ls).find(k => k.includes('-numbers'))) {
                                    self.setState({ promptUserAllTimeNumbersUpdate: true });
                                } else {
                                    localStorage.setItem('updated-db-' + user.uid, true);
                                }
                            }
                        } else if (window.location.pathname !== "/login") {
                            document.querySelector("html").style.setProperty('--bg', null);
                            document.querySelector("html").style.setProperty('--color', null);
                            signedIn = false;
                            signinProvider = null;
                            gotInitialData = true;
                            self.props.history.push('/');
                        } else {
                            gotInitialData = true;
                        }
                        self.setState({ loading: false })
                    });
                });
                document.body.classList.remove('loading');
            });
        })
    }

    toggleDarkTheme = () => {
        if (document.body.getAttribute('theme') === 'dark') {
            document.body.removeAttribute('theme');
            this.setState({ theme: 'light' })
            localStorage.removeItem('theme')
            theme = createTheme({
                palette: {
                    type: 'light',
                },
            });
        } else {
            document.body.setAttribute('theme', 'dark')
            this.setState({ theme: 'dark' })
            localStorage.setItem('theme', 'dark')
            theme = createTheme({
                palette: {
                    type: 'dark',
                },
            });
        }
    }

    toggleAppStoreRedirect(redirect) {
        if (redirect === true) {
            window.open('https://apps.apple.com/us/app/sportle-games/id6476887936?platform=iphone');
        }
        this.setState({ showAppStoreRedirect: !this.state.showAppStoreRedirect }, () => {
            if (!this.state.showAppStoreRedirect) { localStorage.setItem("app-store-redirect", true); }
        })
    }

    toggleGooglePlayRedirect(redirect) {
        if (redirect === true) {
            window.open('https://play.google.com/store/apps/details?id=com.cameron.sportle');
        }
        this.setState({ showGooglePlayRedirect: !this.state.showGooglePlayRedirect }, () => {
            if (!this.state.showGooglePlayRedirect) { localStorage.setItem("google-play-redirect", true); }
        })
    }

    async allTimeNumbersUpdatePrompt(cntnue) {
        if (!cntnue) {
            this.setState({ promptUserAllTimeNumbersUpdate: false })
            localStorage.setItem('updated-db-' + auth.currentUser.uid, true);
            return;
        }
        const ls = localStorage;
        await Promise.all(Object.keys(ls).map(async (key, idx) => {
            if (key.includes('-numbers')) {
                await getRecordAndStreak(null, key, database, auth.currentUser.uid, true, this.state.sportsLeaderboards);
            }
        }));
        this.setState({ promptUserAllTimeNumbersUpdate: false })
        localStorage.setItem('updated-db-' + auth.currentUser.uid, true);
    }

    /*givewaway*/
    /*let date = new Date();
    let hasReceivedModal;
    let hasReceivedModalBool = true;
    if (date.getDate() > 13 && date.getDate() < 19) {
        hasReceivedModal = localStorage.getItem('giveaway-modal');
        if (hasReceivedModal) {
            const hasReceivedModalJson = JSON.parse(hasReceivedModal);
            if (!hasReceivedModalJson.expiry || hasReceivedModalJson.expiry < new Date()) {
                hasReceivedModalBool = false;
            }
        } else {
            hasReceivedModalBool = false;
        }
    }
    const [receivedModal, setReceivedModal] = useState(hasReceivedModalBool);
    const receiveModal = () => {
        date = new Date();
        date.setHours(0, 0, 0, 0)
        const expire = date.setDate(date.getDate() + 1);
        localStorage.setItem('giveaway-modal', JSON.stringify({ expiry: expire }));
        setReceivedModal(true)
    }
    const toggleGiveawayModal = () => {
        setReceivedModal(!receivedModal);
    }
    const srcs = [
        "//fanatics.frgimages.com/FFImage/thumb.aspx?i=/productimages/_4195000/ff_4195848-92adb03423c046c1e26b_full.jpg&amp;w=340",
        "//fanatics.frgimages.com/FFImage/thumb.aspx?i=/productimages/_3167000/ff_3167132_full.jpg&amp;w=340",
        "//fanatics.frgimages.com/FFImage/thumb.aspx?i=/productimages/_3592000/ff_3592478-caaf08873960b4901356_full.jpg&amp;w=340"]
    
    const [imgLoaded, setImgLoaded] = React.useState([false, false, false])
    
    const loadImage = (idx) => {
        let images = [...imgLoaded]
        images[idx] = true;
        setImgLoaded(images);
    }*/
    /**/

    render() {
        const today = new Date();
        const siteDown = false; //today.getFullYear() === 2022 && today.getMonth() === 5 && today.getDate() <= 12
        const isAuthenticated = signedIn;
        const spinner = this.state.loading || !gotInitialData;
        return (
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Layout history={this.props.history} loading={spinner} location={this.props.location} theme={this.state.theme} toggleDarkTheme={this.toggleDarkTheme} signinProvider={signinProvider} signOut={this.signOut}>
                    <Suspense fallback={
                        <div className="d-flex mt-5 justify-content-center">
                            <div className="spinner-border" style={{ color: 'white' }} role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }>
                        {spinner ? <div className="d-flex mt-5 justify-content-center">
                            <div className="spinner-border team-color" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div> : siteDown ?
                            <SiteDown />
                            :
                            <Switch>
                                <Route exact path='/login' component={SignIn} />
                                <PrivateRoute exact path='/' allTimeNumbersUpdatePrompt={this.state.promptUserAllTimeNumbersUpdate ? this.allTimeNumbersUpdatePrompt : null} component={Home} />
                                <PrivateRoute exact path='/profile' component={Profile} />
                                <PrivateRoute exact path='/NFL' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NflHome} />
                                <PrivateRoute exact path='/NFL/:NflTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NflHome} />
                                <PrivateRoute exact path='/NBA' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NbaHome} />
                                <PrivateRoute exact path='/NBA/:NbaTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NbaHome} />
                                <PrivateRoute exact path='/WNBA' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={WnbaHome} />
                                <PrivateRoute exact path='/WNBA/:NbaTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={WnbaHome} />
                                <PrivateRoute exact path='/NHL' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NhlHome} />
                                <PrivateRoute exact path='/NHL/:NhlTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NhlHome} />
                                <PrivateRoute exact path='/MLB' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={MlbHome} />
                                <PrivateRoute exact path='/MLB/:MlbTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={MlbHome} />
                                <PrivateRoute exact path='/MLS' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={MlsHome} />
                                <PrivateRoute exact path='/MLS/:MlsTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={MlsHome} />
                                <PrivateRoute exact path='/NWSL' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NwslHome} />
                                <PrivateRoute exact path='/NWSL/:NwslTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={NwslHome} />
                                <PrivateRoute exact path='/premier-league' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={PremHome} />
                                <PrivateRoute exact path='/premier-league/:PremTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={PremHome} />
                                <PrivateRoute exact path='/la-liga' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={LaLigaHome} />
                                <PrivateRoute exact path='/la-liga/:PremTeam' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={LaLigaHome} />
                                <PrivateRoute exact path='/all' theme={this.state.theme} getStreamData={this.getStreamData} users={this.state.users} sportsGuesses={this.state.sportsGuesses} sportsLeaderboards={this.state.sportsLeaderboards} component={AllHome} />
                            </Switch>
                        }
                        {
                            (this.state.showAppStoreRedirect || this.state.showGooglePlayRedirect) &&
                            <Dialog
                                className="app-store"
                                onClose={this.state.showAppStoreRedirect ? this.toggleAppStoreRedirect : this.toggleGooglePlayRedirect}
                                open={true}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                TransitionComponent={Transition}>
                                <DialogTitle id="alert-dialog-title">Sportle for {this.state.showAppStoreRedirect ? 'iOS' : 'Android'}</DialogTitle>
                                <DialogContent className="modal-body">
                                    <DialogContentText id="alert-dialog-description">
                                        Sportle for {this.state.showAppStoreRedirect ? 'iOS' : 'Android'} offers an <b>enhanced user experience</b> as well as <b>numerous features that the web version does not provide</b>.
                                    </DialogContentText>
                                    <DialogContentText id="alert-dialog-description">
                                        Would you like us to send you to {this.state.showAppStoreRedirect ? <><span>the </span><a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936?platform=iphone">App Store</a></> : <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">Google Play</a>} to <b>download Sportle for free</b>?
                                    </DialogContentText>
                                    <div className="app-store-img-container">
                                        <img src={require('./assets/AppIcon~ios-marketing.png')} alt={"App Store logo"} style={{ borderRadius: '18px' }} />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="secondary" onClick={this.state.showAppStoreRedirect ? this.toggleAppStoreRedirect : this.toggleGooglePlayRedirect}>No</Button>
                                    <Button color="primary" onClick={() => this.state.showAppStoreRedirect ? this.toggleAppStoreRedirect(true) : this.toggleGooglePlayRedirect(true)} autoFocus>
                                        Yes, please!
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        }

                        {/*<Modal isOpen={!receivedModal} toggle={receiveModal}>
                            <ModalHeader toggle={receiveModal}>Jersey Giveaway!</ModalHeader>
                            <ModalBody>
                                {srcs.map((src, idx) =>
                                    <div style={{ display: 'inline' }} key={idx}>
                                        {!imgLoaded[idx] && (
                                            <div className="d-inline" >
                                                <div className="spinner-border text-secondary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>)}

                                        <img className="giveaway-img" src={src} alt={"Jersey"} onLoad={() => loadImage(idx)} style={!imgLoaded[idx] ? { display: 'none' } : {}} />
                                    </div>
                                )}
                                <br />
                                <br />
                                <ul>
                                    <li>Play the "All" sports game for a chance to win a free jersey of your choice from any of the MLB, NBA, and NFL shops.</li>
                                    <li>The rules are simple: play the game and tweet your results using the <span className="btn fa fa-twitter fake" data-show-count="false" /> button that appears upon completion.</li>
                                    <li>Correctly guessing the mystery player will not impact your likelihood of being selected.</li>
                                    <li>Limited to one entry per user per day.</li>
                                    <li>Winner will be disclosed on Sunday, June 19 by Twitter user <a href="https://twitter.com/CamJones997" target="_blank">@CamJones997</a>.</li>
                                    <li>Jersey price will be capped at $130 USD.</li>
                                </ul>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="secondary" onClick={receiveModal}>Close</Button>
                            </ModalFooter>
                        </Modal>*/}
                    </Suspense>
                </Layout>
            </ThemeProvider>
        );
    }
}
// Connect is wrapped by withRouter. 
// This is the only way history.push and Redirect will work when also using Connect. 
const routedApp = withRouter(connect(
    (state) => {
        const { nav } = state;
        return {
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, navActionCreators), dispatch)
        }
    }
)(App))

export default routedApp;
