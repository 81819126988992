import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { sportleDataActionCreators } from '../../stores/sportle-store';
import { bindActionCreators } from 'redux';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import SportContainer from '../parts/sport-container';
import { Sport } from '../../helpers/sport-enum';

class AllHome extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            version: null,
            help: false,
            loading: true,
            showLeaderboard: false
        }

        this.load = this.load.bind(this);
        this.toggleHelp = this.toggleHelp.bind(this);
    }

    toggleHelp() {
        this.setState({ help: !this.state.help });
    }

    componentDidMount() {
        document.body.classList = "all"
        this.load();
    }

    componentDidUpdate(prevProps) {
        if (this.props.theme !== prevProps.theme) this.setColors();
    }

    load(callback) {
        document.querySelector("html").style.setProperty('--contrastedColor', '#ff8e3a');
        this.setState({ loading: true }, () => {
            this.props.getStreamData('All');
            this.setColors();
            this.props.actions.getSportleMetadata().then(() => {
                const sportleMetadata = this.props.sportle.sportleMetadata;
                let maxGuessesBySport = sportleMetadata['maxGuesses'];
                const maxGuesses = maxGuessesBySport["All"];
                const showLeaderboardBySport = sportleMetadata['showLeaderboard'];
                const showLeaderboard = showLeaderboardBySport['All'];
                this.setState({ version: sportleMetadata['version'], showLeaderboard, loading: false, maxGuesses });
                if (callback) callback();
            });
        })
    }

    setColors() {
        document.querySelector("html").style.setProperty('--contrastedModalColor', this.props.theme === 'light' ? '#013368' : '#ff8e3a');
    }

    render() {
        return (
            <>
                <div className="home-wrapper">
                    <SportContainer loading={this.state.loading} game={Sport.All} team={'all'} database={this.props.database} showLeaderboard={this.state.showLeaderboard} users={this.props.users} sportsGuesses={this.props.sportsGuesses} sportsLeaderboards={this.props.sportsLeaderboards} load={this.load} maxGuesses={this.state.maxGuesses} toggleHelp={this.toggleHelp} version={this.state.version} />
                </div>
                <div className="app-store-container">
                    <a target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/sportle-games/id6476887936?platform=iphone">
                        <img className="app-store" src={require('../../assets/app-store-badge-128x128.png')} alt={"View In App Store"} />
                    </a>
                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.cameron.sportle">
                        <img className="google-play" src={require('../../assets/google_play.png')} alt={"View In Google Play"} />
                    </a>
                </div>
                {
                    this.state.help &&
                    <Modal isOpen={true} toggle={this.toggleHelp}>
                        <ModalHeader>HOW TO PLAY</ModalHeader>
                        <ModalBody>
                            <ul>
                                <li><b>Welcome to the most challenging game Sportle has to offer!</b></li>
                                <li>You have {this.state.maxGuesses} attempts to guess the daily mystery player, selected from any of the following leagues: NFL, NBA, WNBA, NHL, MLB, MLS, La Liga, NWSL, and Premier League!</li>
                                <li><span className="c">Green</span> in any column indicates an exact match!</li>
                                <li><span className="almost">Yellow</span> in the position column indicates a partial match to the mystery player's position (if they play multiple positions)</li>
                                <li><span className="almost">Yellow</span> in the weight column indicates your guess is within 10 pounds/4.5 kilograms of the mystery player</li>
                                <li><span className="almost">Yellow</span> in any other column indicates this attribute is within 2 (number, height [2 inches/5 centimeters], age, experience) of the mystery player</li>
                                <li>A new mystery player every day!</li>
                            </ul>
                        </ModalBody>
                        <IconButton className="close-modal-basic" onClick={this.toggleHelp}>
                            <Close />
                        </IconButton>
                    </Modal>
                }
            </>
        );
    }
}
export default connect(
    (state) => {
        const { sportle } = state;
        return {
            sportle
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sportleDataActionCreators), dispatch)
        }
    }
)(AllHome)