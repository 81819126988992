import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import Confetti from 'react-confetti';
import PlayerData from './player-data';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import AllTimeNumbers from './AllTimeNumbers';
import ShareLinks from './share-links';

class ResultModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        const { c } = this.props;
        return (
            <Modal isOpen={true} toggle={this.props.toggleModal} className="todays-player">
                {this.props.guessedCorrect &&
                    <div style={{ position: 'fixed', left: '0px', top: '0px' }}>
                        <Confetti
                            numberOfPieces={3000}
                            tweenDuration={50000}
                            recycle={false}
                        />
                    </div>
                }
                <ModalHeader className={!this.props.guessedCorrect ? 'failure' : ''}>{this.props.guessedCorrect ? 'You got it!' : 'You have run out of guesses'}</ModalHeader>
                <ModalBody>
                    {!this.props.guessedCorrect && <h3 className="answer">Answer:</h3>}
                    <PlayerData player={c} league={this.props.league} />
                    <br />
                    <AllTimeNumbers streak={this.props.streak} allTimeRecord={this.props.allTimeRecord} />
                </ModalBody>
                <ModalFooter>
                    <ShareLinks modal operatingSystem={this.props.operatingSystem} IOS={this.props.IOS} ANDROID={this.props.ANDROID} text={this.props.text} tweetText={this.props.tweetText} streak={this.props.streak} allTimeRecord={this.props.allTimeRecord} />
                    <IconButton className="close-modal" title="Close" onClick={this.props.toggleModal}>
                        <Close />
                    </IconButton>
                </ModalFooter>
            </Modal >
        );
    }
}
export default connect()(ResultModal);
