import React, { useEffect, useState } from 'react';
import { Share, Twitter } from '@mui/icons-material';
import { ButtonDropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { IconButton } from '@mui/material';

const ShareLinks = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    const copy = (e) => {
        var copyText = props.text.replaceAll('%0D%0A', '\n')
        navigator.clipboard.writeText(copyText)

        const button = e.target;
        button.disabled = true;
        const delay = ms => new Promise(res => setTimeout(res, ms));
        const originalContent = button.textContent;
        button.textContent = 'Copied!'
        delay(3000).then(() => {
            button.textContent = originalContent
            button.disabled = false;
        })
    }

    const submitTweet = () => {
        let tweetLink = document.createElement('a');
        tweetLink.setAttribute('href', "https://twitter.com/share?ref_src=twsrc%5Etfw&text=" + (props.tweetText ?? props.text));
        tweetLink.setAttribute('target', "_blank");
        tweetLink.setAttribute('rel', "noopener noreferrer");
        tweetLink.setAttribute('data-show-count', 'false');

        tweetLink.style.display = 'none';
        document.body.appendChild(tweetLink);
        tweetLink.click();

        document.body.removeChild(tweetLink);
    }

    return (
        props.text ?
            <div className={`share-links${props.mobile ? ' mobile' : ''}${props.desktop ? ' desktop' : ''}`}>
                <ButtonDropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                    <DropdownToggle className={`links-toggle${props.modal ? ' modal-links' : ''}`}>
                        <IconButton className="toggler">
                            <Share className="share" />
                        </IconButton>
                    </DropdownToggle>
                    <DropdownMenu right={!props.modal}>
                        <IconButton className="twitter light" onClick={submitTweet}>
                            <Twitter />
                        </IconButton>
                        {props.operatingSystem === props.IOS &&
                            <IconButton className="light">
                                <a className="btn fa fa-comment" href={"sms:?&body=" + props.text.replaceAll("%0A", "")} />
                            </IconButton>
                        }
                        {props.operatingSystem === props.ANDROID &&
                            <IconButton className="light">
                                <a className="btn fa fa-comment" href={"sms:?body=" + props.text} />
                            </IconButton>
                        }
                        <button className="btn btn-info fa copy" onClick={(e) => copy(e)}>Copy</button>
                    </DropdownMenu>
                </ButtonDropdown>
            </div>
            : <></>
    );
}

export default ShareLinks;

