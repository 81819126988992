import React, { useEffect, useState } from 'react';
import { contrast, hexToRgb } from '../../helpers/functions';
import { TextField, Autocomplete } from '@mui/material';

const Selection = (props) => {
    const [autocompleteKey, setAutoCompleteKey] = useState(new Date().getTime());

    const compareStrings = (a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;

        return 0;
    }

    const compare = (a, b) => a.label.localeCompare(b.label);

    let options = [];
    if (props.team || props.ignoreTeam) {
        props.validPlayers.filter(it => it && it.removed !== true && it.removed !== "true").forEach(player => {
            options.push({ label: player.name, value: player.name, c: player.c })
        })
        options.sort(compare)
    }

    const getPlaceholder = () => {
        if (props.c) { return "You got it!" }
        else if (props.guessNum > props.maxGuesses) { return "You have run out of guesses." }
        else if (props.disabled && !props.loading) { return "Select a team before guessing." }
        else if (props.disabled) { return "Loading..." }
        else if (props.guessNum === 1) { return "Guess any player..." }
        else { return `Guess ${props.guessNum} of ${props.maxGuesses}` }
    }

    useEffect(() => {
        if (props.ignoreTeam) {
            //all sports
            document.querySelector("html").style.setProperty('--contrastedPlayerSelectOptionTextColor', 'var(--bg)');
        } else if (props.teamColors) {
            let contrastedColor;
            const primaryContrastRgb = hexToRgb(props.teamColors.primaryColor);
            const bg = hexToRgb("#ffffff");
            const primaryContrast = contrast(bg, primaryContrastRgb);
            if (primaryContrast < 2.1) {
                const secondaryContrastRgb = hexToRgb(props.teamColors.secondaryColor);
                const secondaryContrast = contrast(bg, secondaryContrastRgb);
                if (secondaryContrast > primaryContrast) {
                    contrastedColor = props.teamColors.secondaryColor;
                }
            }
            if (!contrastedColor) {
                contrastedColor = props.teamColors.primaryColor;
            }
            document.querySelector("html").style.setProperty('--contrastedPlayerSelectOptionTextColor', '#' + contrastedColor);
        }
    }, [props.team])

    const onChange = (val) => {
        props.onChange(val);
        setAutoCompleteKey(new Date().getTime())
    }
    return (
        <div className={`selection player${props.disabled ? ' disabled' : ''}${!props.team ? ` empty${props.ignoreTeam ? ' ignore-team' : ''}${props.guessNum > props.maxGuesses || (props.disabled && !props.c && !props.loading) ? ' incorrect' : ''}` : props.guessNum > props.maxGuesses || (props.disabled && !props.c && !props.loading) ? ' incorrect' : ' correct-or-active'}${!props.loading && props.disabled && props.c ? ' correct' : ''}`}>
            <Autocomplete
                key={autocompleteKey}
                fullWidth
                disableClearable
                autoHighlight
                selectOnFocus={false}
                classes={{ popper: 'player-popper' }}
                className={props.disabled ? `disabled${!props.loading && (props.c || props.guessNum > props.maxGuesses) ? ' finished' : ''}` : ""}
                disabled={props.disabled}
                onChange={(_, val) => onChange(val)}
                options={options.map(option => option.label)}
                value=""
                renderInput={params => <TextField {...params} label={getPlaceholder()} />}
            />
        </div>
    );
}

export default Selection;